import { Pipe, PipeTransform } from '@angular/core';
import { isObservable, of, Observable } from 'rxjs';
import { map, startWith, catchError } from 'rxjs/operators';
import { BaseMoblet } from '../../models/moblets';

@Pipe({
  name: 'obsLoading',
})
export class ObsLoading implements PipeTransform {
  transform(val: any): Observable<Partial<BaseMoblet<any>> | any> {
    return isObservable(val)
      ? val.pipe(
          map((value: any) => Object.assign({}, { loading: false }, value)),
          startWith({ loading: true }),
          catchError(error => of({ loading: false, error })),
        )
      : val;
  }
}
