export class SetHomeRoute {
  static readonly type = '[core] Set Home Route';
}

export class LoadSignupForm {
  static readonly type = '[core] Load Signup Form';
}

export class LoadCountriesDDI {
  static readonly type = '[core] Load Countries With DDI';
}
