import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Colors } from '@core/models';
import { WebMoblet } from '@core/models/moblets';
import { AppDefState } from '@core/state/appdef';
import { AuthState } from '@core/state/auth';
import { NavController, Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { MobletService } from './moblet.service';
import { TemplateParser } from './template-parser/template-parser';
const { Browser, Toast } = Plugins;

export enum MobletsRoutes {
  'uframe' = 'uframe',
  'm-tawkto' = 'm-tawkto',
  'iframe' = 'iframe',
}

@Injectable({ providedIn: 'root' })
export class InAppBrowserService {
  private _superClass: string;

  private templateParser: TemplateParser;
  constructor(
    private store: Store,
    private platform: Platform,
    private navCtrl: NavController,
    protected mobletService: MobletService,
  ) {
    this.templateParser = new TemplateParser(store);
  }

  public get superClass(): string {
    return this._superClass;
  }

  public set superClass(value: string) {
    this._superClass = value;
  }

  /**
   * carrega os dados do moblet para pegar a URL
   * @returns void
   */
  public getMobletInfo(instanceId: number): void {
    this.feedbackToast('Carregando página...');
    this.mobletService.loadMoblet(instanceId).subscribe(
      async (moblet: WebMoblet) => this.treatMobletInfo(moblet),
      (err: any) =>
        this.feedbackToast('Não foi possível carregar os dados da Página Web'),
    );
  }

  private async treatMobletInfo(moblet: WebMoblet): Promise<void> {
    const mobletHasUrl: boolean = !!moblet?.frame?.url;
    const url: string = mobletHasUrl
      ? this.templateParser.parse(moblet.frame.url)
      : null;

    if (!url) {
      return this.feedbackToast('Não é possível abrir Página Web');
    }

    await this.openBrowser(url);
  }

  /**
   * deve existir o valor da superClass para a validação
   * @returns boolean
   */
  public get mustOpenBrowser(): boolean {
    if (!this.superClass) {
      throw new Error('superClass deve conter um valor');
    }
    return (
      this.platform.is('capacitor') && this.superClass === MobletsRoutes.iframe
    );
  }

  mustOpenBrowserWhenLogged(instanceId: number): boolean {
    return this.mustOpenBrowser && this.mobletRequiredAuth(instanceId);
  }

  public async openBrowser(url: string): Promise<void> {
    const { header_color } = this.store.selectSnapshot<Colors>(
      AppDefState.getColors,
    );

    try {
      await Browser.open({
        presentationStyle: 'fullscreen',
        toolbarColor: header_color,
        url,
      });
    } catch (error) {
      this.feedbackToast('Não foi possível abrir Página Web');
    }

    Browser.addListener('browserFinished', (info: any) => {
      console.log('browserFinished', info);
      Browser.removeAllListeners();
    });
  }

  private async feedbackToast(text: string): Promise<void> {
    await Toast.show({
      text,
      duration: 'long',
      position: 'bottom',
    });
  }

  private mobletRequiredAuth(instanceId: number): boolean {
    const { access_control } = this.store.selectSnapshot(
      AppDefState.getPage(instanceId),
    );
    return access_control === 'true' && !this.isAuthenticated;
  }

  get isAuthenticated(): boolean {
    return this.store.selectSnapshot(AuthState.isAuthenticated);
  }

  public redirecToLogin(): void {
    this.navCtrl.navigateForward(['/auth/login']);
  }
}
