<ion-app>
  <ion-split-pane contentId="main-content" *transloco="let t">
    <ion-menu
      contentId="main-content"
      type="overlay"
      *ngIf="partialAppDef$ | async as partialAppDef"
    >
      <div
        class="fabapp-menu-header"
        [class.small]="!partialAppDef?.info?.login_enable"
      >
        <ng-container
          *ngIf="partialAppDef?.info?.login_enable; else loginDisabledTemplate"
        >
          <div class="fabapp-menu-header__user" (click)="goSettings()">
            <ng-container *ngIf="user$ | async as user">
              <ion-avatar slot="start">
                <img
                  *ngIf="!user.picture"
                  src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y?s=200"
                />
                <img *ngIf="user.picture" [src]="user.picture" />
              </ion-avatar>
              {{ user.name }}
            </ng-container>
          </div>
          <div class="fabapp-menu-header__actions">
            <ion-button
              *ngIf="!(user$ | async)"
              color="light"
              fill="clear"
              (click)="login()"
            >
              {{ t.logIn }}
              <ion-icon slot="end" name="log-in-outline"></ion-icon>
            </ion-button>
          </div>
        </ng-container>
      </div>
      <ion-content>
        <div class="fabapp-menu-content">
          <ion-list>
            <ion-menu-toggle auto-hide="false">
              <ion-item
                lines="none"
                [routerDirection]="'root'"
                (click)="setHomePage()"
              >
                <ion-icon name="home" slot="start"></ion-icon>
                <ion-label>{{ t.home }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle
              auto-hide="false"
              *ngFor="let page of pages$ | async; trackBy: trackByIndex"
            >
              <ion-item
                lines="none"
                [routerDirection]="'forward'"
                (click)="navigate(page)"
              >
                <ion-avatar slot="start">
                  <img [src]="page.icon" />
                </ion-avatar>
                <ion-label>{{ page.name }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </div>
        <div class="fabapp-menu-footer">
          <ion-list>
            <ion-item lines="none">
              <ion-icon name="document-text" slot="start"></ion-icon>
              <ion-label>{{ t.termsOfUse }}</ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-icon name="document-text" slot="start"></ion-icon>
              <ion-label>{{ t.privacyPolicy }}</ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-avatar slot="start">
                <img [src]="partialAppDef.info?.icon" />
              </ion-avatar>
              <ion-label>{{ partialAppDef.info?.name }}</ion-label>
            </ion-item>
          </ion-list>
        </div>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>

<ng-template #loginDisabledTemplate>
  <ng-container *transloco="let t">
    {{ t.logInDisabled }}
  </ng-container>
</ng-template>
