export class RegularSignUp {
  static readonly type = '[auth] Sign up with e-mail or phone';

  constructor(public user: any) {}
}

export class UpdateUser {
  static readonly type = '[auth] update user';

  constructor(public user: any) {}
}

export class UploadAvatar {
  static readonly type = '[auth] upload avatar';

  constructor(public avatar: any) {}
}

export class DeleteAvatar {
  static readonly type = '[auth] delete avatar';

  constructor() {}
}

export class DeleteUser {
  static readonly type = '[auth] delete user';
}

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public payload: { email: string; password: string }) {}
}

export class RefreshToken {
  static readonly type = '[Auth] Refresh Token';
}

export class Logout {
  static readonly type = '[Auth] Logout';
}
