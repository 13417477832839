import { Colors } from '@core/models';
import * as tinycolor from 'tinycolor2';

/*  Lista de cores do portal
 *
 *  Cabeçalho: header_color
 *  Títulos: font_color
 *  Fundo: background_color
 *  Navegação: navigation_color
 */

interface CustomColorBlock {
  property: string;
  color: string;
}

const KEYS: object = {
  '--ion-color-primary': 'header_color',
  '--ion-color-primary-contrast': 'navigation_color',
  '--title-color': 'font_color',
  '--background-color': 'background_color',
  '--font-color': 'font_color',
};

export function setCssVariables(colors: Colors): void {
  Object.keys(KEYS).map((key: string) => {
    document.documentElement.style.setProperty(key, colors[KEYS[key]]);
  });

  generateContrastColors(colors).map((block: CustomColorBlock) => {
    document.documentElement.style.setProperty(block.property, block.color);
  });
}

function generateContrastColors(colors: Colors): CustomColorBlock[] {
  const primaryColor: any = tinycolor(colors.header_color);
  const navigationColor: any = tinycolor(colors.navigation_color);

  return [
    {
      property: '--fabapp-white-brackground-primary-color',
      color: primaryColor.isDark()
        ? primaryColor
        : navigationColor.isDark()
        ? navigationColor
        : '#000',
    },
    {
      property: '--fabapp-white-brackground-navigation-color',
      color: navigationColor.isDark()
        ? navigationColor
        : primaryColor.isDark()
        ? primaryColor
        : '#000',
    },
  ];
}
