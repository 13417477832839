import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AdmobAdsService } from '@core/services/ads';
import {
  FabappAdsState,
  RemoveListenersOfAds,
  SetAdsAsClicked,
} from '@core/state/ads';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { BannerAndModalOfDivulgation } from './interfaces/banner.model';

@Component({
  selector: 'fabapp-banner-footer',
  templateUrl: './banner-footer.component.html',
  styleUrls: ['./banner-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerFooterComponent implements OnInit, OnDestroy {
  public showBanner: boolean = false;
  bannerSubscription: Subscription;
  public bannerData$: Observable<BannerAndModalOfDivulgation>;
  constructor(
    public adsService: AdmobAdsService,
    private store: Store,
    private cdr: ChangeDetectorRef,
  ) {}

  async ngOnInit(): Promise<void> {
    this.loadBanner();
    this.createSubscriptionOfAdmobBanner();
  }

  async ngOnDestroy(): Promise<void> {
    this.store.dispatch(new RemoveListenersOfAds()).toPromise();
    if (this.bannerSubscription) {
      this.bannerSubscription.unsubscribe();
    }
  }

  /**
   * @description
   * se o banner do admob estiver sendo mostrado
   * será emitido uma que ele está sendo mostrado
   *
   * @returns void
   */
  public createSubscriptionOfAdmobBanner(): void {
    this.bannerSubscription = this.store
      .select(FabappAdsState.isShowingBanner)
      .subscribe((banner: boolean) => {
        this.showBanner = banner;
        this.cdr.detectChanges();
      });
  }

  public loadBanner(): void {
    this.bannerData$ = this.store.select(FabappAdsState.banner);
  }

  public async openLink(link: string): Promise<void> {
    this.store.dispatch(new SetAdsAsClicked('banner'));
    if (link && typeof link !== 'number') {
      window.open(link, '_blank');
    }
  }
}
