<ion-header translucent>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()"
        ><ion-icon slot="icon-only" size="large" name="close"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-item class="country-input">
    <ion-label position="floating" *ngIf="inputTitle | async as title">{{
      title
    }}</ion-label>
    <ion-input type="text" (ionChange)="filterCountries($event)"></ion-input>
  </ion-item>
  <div class="countries-list">
    <ng-container
      *ngFor="let country of countries$ | async; trackBy: trackByIndex"
    >
      <div
        class="country-item"
        *ngIf="
          country.callingCodes[0] &&
          country.name.toLowerCase().indexOf(searchString) >= 0
        "
        (click)="selectCountry(country)"
      >
        <div class="country-ddi">+{{ country.callingCodes[0] }}</div>
        <img class="country-flag" [src]="country.flag" />
        <div class="country-name">{{ country.name }}</div>
      </div>
    </ng-container>
  </div>
</ion-content>
