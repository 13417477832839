import { AbstractControl } from '@angular/forms';

/*
 * Validador de cpf usando o algoritmo encontrado neste gist
 * https://gist.github.com/roneigebert/10d788a07e2ffff88eb0f1931fb7bb49
 */
// tslint:disable: typedef
export function CpfValidator(control: AbstractControl) {
  const cpf: string = control.value.replace(/[^0-9]/g, '');

  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return { cpf: true };
  }

  let soma = 0;
  let resto: number;

  for (let i = 1; i <= 9; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  }

  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  if (resto !== parseInt(cpf.substring(9, 10), 10)) {
    return { cpf: true };
  }

  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  }

  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  if (resto !== parseInt(cpf.substring(10, 11), 10)) {
    return { cpf: true };
  }

  return null;
}
