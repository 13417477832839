import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { NavigationService } from '../../services/navigation.service';
import { Back } from './navigation.actions';

export interface NavigationStateModel {}

@State<NavigationStateModel>({
  name: 'navigation',
  defaults: {},
})
@Injectable()
export class NavigationState {
  constructor(private navigationService: NavigationService) {}

  @Action(Back)
  async back(
    ctx: StateContext<NavigationStateModel>,
    { instanceId, itemId },
  ): Promise<any> {
    this.navigationService.back({ instanceId, itemId });
  }
}
