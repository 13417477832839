import {
  animate,
  group,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppDef, User } from '@core/models';
import { NavigationService } from '@core/services/navigation.service';
import { AppDefState } from '@core/state/appdef';
import { AuthState, Login } from '@core/state/auth';
import {
  ModalController,
  NavController,
  ToastController,
} from '@ionic/angular';
import {
  translate,
  TranslocoService,
  TRANSLOCO_SCOPE,
} from '@ngneat/transloco';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserStatus } from '../../../core/enums';
import { SignupPage } from '../signup/signup.page';

const TRANSLATION_SCOPE = 'login';
let LANG: string;
@Component({
  selector: 'fabapp-login',
  templateUrl: 'login.page.html',
  styleUrls: ['login.page.scss'],
  animations: [
    trigger('slideDown', [
      state('0', style({ height: '0px', opacity: 0 })),
      state('1', style({ height: '*', opacity: 1 })),
      transition('0 => 1', [
        style({ height: '0', opacity: 0 }),

        group([
          animate(300, style({ height: '*' })),
          animate('400ms ease-in-out', style({ opacity: '1' })),
        ]),
      ]),
      transition('1 => 0', [
        style({ height: '*', opacity: 1 }),

        group([
          animate(300, style({ height: 0 })),
          animate('200ms ease-in-out', style({ opacity: '0' })),
        ]),
      ]),
    ]),
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: TRANSLATION_SCOPE,
    },
  ],
})
export class LoginPage implements OnInit {
  @Input() isModal = false;
  @Input() intentUrl: string;
  @Select(AppDefState.getFromAppDef(['info', 'logo']))
  partialAppDef$: Observable<Partial<AppDef>>;
  isSubmitting = false;

  translationScope: string;

  passwordType = 'password';
  mask = { mask: '', type: 'text' };
  isEmail = true;
  callingCode: string;
  emailValidations = [
    Validators.required,
    Validators.email,
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
  ];
  phoneValidations = [Validators.required];

  form = this.fb.group({
    email: ['', this.emailValidations],
    password: ['', Validators.required],
  });

  constructor(
    private navigationService: NavigationService,
    private navCtrl: NavController,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    public modalController: ModalController,
    public toastController: ToastController,
    private translocoService: TranslocoService,
  ) {
    LANG = this.translocoService.getActiveLang();
  }

  ngOnInit(): void {
    console.log('é modal, veio do guard', this.isModal);
    console.log('url q eh pra ir', this.intentUrl);
  }

  get email(): AbstractControl {
    return this.form.get('email');
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

  setMask(mask: string): void {
    this.mask = { mask, type: 'tel' };
  }

  onInputUsername(value: string): void {
    value = value.replace(' ', '').replace('-', '');
    if (value.length < 2) {
      this.isEmail = true;
      this.mask = { mask: '', type: 'text' };
      this.form.get('email').setValidators(this.emailValidations);
      this.form.get('email').setValue(value);
      this.cdr.detectChanges();
      return;
    }
    // Não mudou nada
    if (this.isEmail !== /^\d+$/.test(value)) {
      return;
    }
    this.isEmail = !/^\d+$/.test(value);

    this.form
      .get('email')
      .setValidators(
        this.isEmail ? this.emailValidations : this.phoneValidations,
      );

    if (!this.isEmail) {
      this.mask = { mask: '99 99999-9999', type: 'tel' };
    } else {
      this.mask = { mask: '', type: 'text' };
      this.email.setValue(value);
    }

    this.cdr.detectChanges();
  }

  async presentToast(translateKey: string): Promise<void> {
    const toast = await this.toastController.create({
      message: translate(translateKey, {}, `${TRANSLATION_SCOPE}/${LANG}`),
      duration: 2000,
    });

    toast.present();
  }

  togglePasswordMode(): void {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
  }

  getCallingCode(callingCode: string): void {
    this.callingCode = callingCode;
  }

  async login(): Promise<boolean> {
    try {
      this.isSubmitting = true;

      const data: any = this.form.getRawValue();

      if (!this.isEmail) {
        data.email = this.callingCode + data.email.replace(/[^\d]+/g, '');
      }
      await this.store.dispatch(new Login(data)).toPromise();
      const user: User = this.store.selectSnapshot(AuthState.getUser);
      if (user.status_id === UserStatus.Waiting) {
        return this.goToUserAwaitingApproval();
      }

      this.presentToast('messages.loginSuccess');

      // TODO: Efetuar o redirecionamento para aba restrita clicada ou home
      if (this.intentUrl) {
        this.modalController.dismiss({ navigate: true });
      } else {
        this.navigationService.returnToHomePage();
      }
    } catch (err) {
      const keyReturned: any = err.error.error || err.error.error_code;

      if (keyReturned === 'user_waiting') {
        return this.goToUserAwaitingApproval();
      }

      const apiReturns: object = {
        invalid_credentials: 'invalidCredentials',
        user_suspended: 'userSuspended',
        // user_waiting: 'userWaiting',
        user_closed: 'userRemoved',
      };

      this.presentToast(
        `messages.${apiReturns[keyReturned] || 'genericError'}`,
      );
    } finally {
      this.isSubmitting = false;
    }
  }

  async goToNewAccount(): Promise<boolean> {
    if (this.isModal) {
      this.modalController.dismiss({ openSignUp: true });
    } else {
      return this.navCtrl.navigateForward('/auth/signup');
    }
  }

  goToUserAwaitingApproval(): Promise<boolean> {
    return this.navCtrl.navigateRoot('/auth/user-awaiting-approval');
  }

  async openSignUpModal(intentUrl: string): Promise<any> {
    const modal: HTMLIonModalElement = await this.modalController.create({
      component: SignupPage,
      componentProps: {
        isModal: true,
        intentUrl,
      },
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    return data;
  }
}
