import { Injectable } from '@angular/core';
import { AppDefService } from '@core/services/app-def.service';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { AppDef, AppLayout, CarouselItem, Colors, Page } from '@core/models';
import { HomeLayout } from '@core/types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetHomeRoute } from '../core/core.actions';
import { LoadAppDef } from './appdef.actions';
import { getTextSize } from '@utils';
import { getIconSize } from 'src/app/utils/parse-icon-size';

@State<AppDef>({
  name: 'appdef',
})
@Injectable()
export class AppDefState {
  constructor(private appDefService: AppDefService) {}

  @Selector()
  static getPages(state: AppDef): Page[] {
    return state.pages;
  }

  static getPage(instanceId: number): (state: AppDef) => Page {
    return createSelector([AppDefState], (state: AppDef) => {
      return state.flattened_pages.find(
        (page: Page) => page.instance.id === `${instanceId}`,
      );
    });
  }

  static getFromAppDef(keys: string[]): (state: AppDef) => Partial<AppDef> {
    // console.log(keys);
    return createSelector([AppDefState], (state: AppDef) => {
      const newState: Partial<AppDef> = {};

      keys.forEach((key: string) => {
        // console.log(key, state);
        newState[key] = state[key];
      });

      return newState;
    });
  }

  @Selector()
  static getLayout(state: AppDef): AppLayout {
    const titlePx2VW: number = +state.layout.title_size * 0.24125;
    const newState: AppLayout = {
      ...state.layout,
      icon_size: state.layout.icon_size_advanced
        ? state.layout.icon_size
        : getIconSize(state.layout.icon_size),
      title_size: state.layout.title_size_advanced
        ? titlePx2VW
        : getTextSize(+state.layout.title_size),
    };
    return newState;
  }

  @Selector()
  static getCarousel(state: AppDef): CarouselItem[] {
    return state.layout.carousel;
  }

  @Selector()
  static getColors(state: AppDef): Colors {
    return state.colors;
  }

  @Selector()
  static getSearch(state: AppDef): boolean {
    return state.search;
  }

  @Selector()
  static getLayoutType(state: AppDef): HomeLayout {
    return state.layout.layout_type;
  }

  @Selector()
  static getAuth(state: AppDef): HomeLayout {
    return state.layout.layout_type;
  }

  @Action(LoadAppDef)
  loadAppDef(ctx: StateContext<AppDef>): Observable<AppDef> {
    return this.appDefService.loadAppDef().pipe(
      tap((newAppDef: AppDef) => {
        const appDef: AppDef = new AppDef(newAppDef);
        ctx.patchState(appDef);
        return ctx.dispatch(new SetHomeRoute());
      }),
    );
  }
}
