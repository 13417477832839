import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { PipesModule } from './pipes/pipes.module';
import { DirectivesModule } from './directives/directives.module';
import { EmptyStateModule } from '../components/empty-state/empty-state.module';
import { BannerFooterModule } from '../components/banner-footer/banner-footer.module';

@NgModule({
  imports: [
    PipesModule,
    CommonModule,
    DirectivesModule,
    EmptyStateModule,
    BannerFooterModule,
  ],
  providers: [HttpClient],
  exports: [
    PipesModule,
    CommonModule,
    DirectivesModule,
    EmptyStateModule,
    BannerFooterModule,
  ],
})
export class CoreModule {}
