export class LoadAds {
  static readonly type = '[app] Load Ads';
}

export class SetAdsAsClicked {
  static readonly type = '[app] Set Ads as Clicked';
  constructor(public ad?: 'modal' | 'banner') {}
}

export class SetAdsAsViewed {
  static readonly type = '[app] Set Ads as Viewed';
  constructor(public ad?: 'modal' | 'banner') {}
}

export class RemoveListenersOfAds {
  static readonly type = '[app] Remove Listeners';
}

export class ShowModalFabApp {
  static readonly type = '[app] Show Modal FabApp';
  constructor(public component?: any) {}
}

export class ShowBanner {
  static readonly type = '[app] Show Banner';
  constructor(public isShow?: boolean) {}
}
