<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button *ngIf="!isModal" defaultHref="home"></ion-back-button>
      <ion-button
        *ngIf="isModal"
        fill="clear"
        (click)="modalController.dismiss()"
      >
        <ion-icon slot="icon-only" name="close" color="dark"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-progress-bar *ngIf="isSubmitting" type="indeterminate"></ion-progress-bar>

<ion-content class="ion-padding">
  <fabapp-user-form (submitForm)="formSubmit($event)"> </fabapp-user-form>
</ion-content>
