import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { ThemingColor } from '@core/types';
import { translate, TranslocoService } from '@ngneat/transloco';

let lang: string;
const TRANSLATION_SCOPE: string = 'shared';
@Directive({
  selector: '[validate]',
})
export class ValidateDirective {
  @Input() validate: FormGroup;
  elementRef: ElementRef;
  clickListeners: (() => void)[] = [];
  renderer: Renderer2;

  constructor(
    el: ElementRef,
    renderer: Renderer2,
    private toast: ToastController,
    private translocoService: TranslocoService,
  ) {
    lang = this.translocoService.getActiveLang();
    this.elementRef = el;
    this.renderer = renderer;
  }

  get form(): FormGroup {
    return this.validate;
  }

  ngOnInit() {
    this.clickListeners = (this.elementRef.nativeElement as any).eventListeners(
      'click',
    );
    (this.elementRef.nativeElement as any).removeAllListeners('click');

    this.renderer.listen(
      this.elementRef.nativeElement,
      'click',
      this.onClick.bind(this),
    );
  }

  async onClick(): Promise<void> {
    console.log(this.form);
    this.markAsTouched(this.form);
    if (!this.form.valid) {
      const validation: string = translate(
        'validations.all',
        {},
        `${TRANSLATION_SCOPE}/${lang}`,
      );
      await this.feedbackToaster(validation);
      return;
    }
    this.clickListeners.map(listener => listener());
  }

  private async feedbackToaster(
    message: string,
    theme: ThemingColor = 'danger',
  ): Promise<void> {
    const toast: HTMLIonToastElement = await this.toast.create({
      message,
      color: theme,
      position: 'top',
      buttons: [
        {
          text: 'ok',
        },
      ],
    });
    toast.present();
  }

  private markAsTouched(formGroup: FormGroup): void {
    formGroup.markAsTouched();
    formGroup.updateValueAndValidity();
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      control.updateValueAndValidity({ onlySelf: false, emitEvent: true });
      if (control.controls) {
        this.markAsTouched(control);
      }
    });
  }
}
