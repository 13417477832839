import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@Component({
  templateUrl: './control-error.component.html',
  styleUrls: ['./control-error.component.scss'],
  selector: 'control-error',
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'shared',
    },
  ],
})
export class ControlErrorComponent implements OnInit {
  @Input('label') label: string;
  @Input('control') control: FormControl;

  private _nameError = 'required';
  domElement: any;

  constructor(el: ElementRef) {
    this.domElement = el.nativeElement;
  }

  ngOnInit() {
    this.control.statusChanges.subscribe(status => this.setClassIonItem);
    this.control.valueChanges.subscribe(value => {
      if (this.control.valid) {
        this.setClassIonItem('VALID');
      }
    });
  }

  getIonItem() {
    let sibling = this.domElement.previousElementSibling;

    while (sibling) {
      if (sibling.matches('ion-item')) return sibling;
      sibling = sibling.previousElementSibling;
    }
    console.error('ADICIONE O INPUT DENTRO DE UM ION-ITEM!!!');
  }

  setClassIonItem(status) {
    const ionItem = this.getIonItem();
    const VALID_CLASSES = ['valid', 'ion-valid', 'ion-touched'];
    const INVALID_CLASSES = ['invalid', 'ion-invalid', 'ion-touched'];

    ionItem.classList.remove(...VALID_CLASSES, ...INVALID_CLASSES);
    if (status === 'INVALID') {
      ionItem.classList.add(...INVALID_CLASSES);
      return (this._nameError = this.getNameError());
    }
    ionItem.classList.add(...VALID_CLASSES);
  }

  get keyI18n() {
    this.setClassIonItem('INVALID');
    return `validations.${this._nameError}`;
  }

  get i18nParams() {
    const label = this.label.toLowerCase();
    const errorFromValidator = Object.entries(this.control.errors)[0][1];
    return { label, ...errorFromValidator };
  }

  getNameError() {
    return Object.entries(this.control.errors)[0][0];
  }
}
