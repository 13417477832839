import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationService } from '@core/services/navigation.service';
import { AuthState } from '@core/state/auth';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RefreshTokenService } from './refresh-token.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  refreshTokenService: RefreshTokenService;

  constructor(private store: Store, navigationService: NavigationService) {
    this.refreshTokenService = new RefreshTokenService(
      store,
      navigationService,
    );
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token: string = this.store.selectSnapshot(AuthState.accessToken);

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request).pipe(
      tap(
        () => {},
        (error: HttpErrorResponse) =>
          this.refreshTokenService.handleResponseError(error, request, next),
      ),
    );
  }
}
