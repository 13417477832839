import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Colors } from '@core/models';
import { Coords, Marker } from '@core/models/maps';
import { AppDefState } from '@core/state/appdef';
import { environment } from '@environments/environment';
import { Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { List } from 'src/app/moblets/list/models';
const { Geolocation } = Plugins;

declare const google: any;
declare const MarkerClusterer: any;
declare const OverlappingMarkerSpiderfier: any;

interface ItemsWithCoordinates {
  latitude: string;
  longitude: string;
}

@Injectable()
export class GeolocationService {
  mapsUrl: string = environment.maps.url;
  private _pinPurple: string = 'assets/icons/person_pin.svg';
  private _userMarkerIcon: string = 'assets/icons/pin-user.png';

  userCurrentCoords: Coords;
  pinSelected: Subject<List> = new Subject();
  mapReference: any;
  private _oms: any;
  private _markerClusterer: any;

  constructor(private platform: Platform, private store: Store) {}

  async getPosition(): Promise<ItemsWithCoordinates> {
    try {
      await Geolocation.requestPermissions();
      const { coords } = await Geolocation.getCurrentPosition();

      this.userCurrentCoords = {
        latitude: coords.latitude,
        longitude: coords.longitude,
      };
      return {
        latitude: coords.latitude.toString(),
        longitude: coords.longitude.toString(),
      };
    } catch (error) {
      this.userCurrentCoords = null;
      throw error;
    }
  }

  private initMap(googleMapsApiKey: string, callback: () => void): void {
    if (!navigator.onLine) {
      return;
    }

    const mapsInstanceNotExist: boolean =
      typeof google === 'undefined' || typeof google.maps === 'undefined';
    if (mapsInstanceNotExist) {
      window['initMap'] = () => {
        callback();
      };

      if (!document.body.children['googleMaps']) {
        this.setScriptOfMaps(googleMapsApiKey);

        this.setScriptOfCluster();
      }
      return;
    }
    callback();
  }

  /**
   * método responsável pela criação do script do Google Maps
   */
  private setScriptOfMaps(googleMapsApiKey: string): void {
    const script: HTMLScriptElement = document.createElement('script');
    script.async = true;
    script.id = 'googleMaps';
    script.src = `${this.mapsUrl}js?&key=${googleMapsApiKey}&callback=initMap`;
    document.body.appendChild(script);
  }

  /**
   * método responsável pela criação do script do Cluster
   */
  private setScriptOfCluster(): void {
    const cluster =
      'https://unpkg.com/@google/markerclustererplus@4.0.1/dist/markerclustererplus.min.js';
    const clusterScript: HTMLScriptElement = document.createElement('script');
    clusterScript.async = true;
    clusterScript.id = 'clusterScript';
    clusterScript.src = cluster;
    document.body.appendChild(clusterScript);
  }

  createMap(target: HTMLElement, googleMapsApiKey: string): Promise<any> {
    return new Promise((resolve: any) => {
      // inicia o mapa passando KEY do google maps
      this.initMap(googleMapsApiKey, () => {
        const map: any = this._newMap(target);
        if (OverlappingMarkerSpiderfier) {
          this._overlappingConfig(map);
        }
        this.mapReference = map;
        resolve(map);
      });
    });
  }

  /**
   * cria nova instancia do mapa
   * @param  {HTMLElement} target
   * @returns any
   */
  private _newMap(target: HTMLElement): any {
    return new google.maps.Map(target, {
      center: {
        lat: +this.userCurrentCoords.latitude,
        lng: +this.userCurrentCoords.longitude,
      },
      zoom: 10,
      disableDefaultUI: true,
    });
  }

  private _overlappingConfig(map: any): void {
    this._oms = new OverlappingMarkerSpiderfier(map, {
      markersWontMove: true,
      markersWontHide: true,
      basicFormatEvents: true,
      ignoreMapClick: true,
    });
  }

  /* 
    // Cria objetos Markers
    // retorna um Marker
   */
  private _addMarker(config: Marker): any {
    const marker: any = new google.maps.Marker(config);
    return marker;
  }

  addUserMaker(userLocation: any): void {
    this._addMarker({
      position: userLocation,
      icon: this._userMarkerIcon,
      map: this.mapReference,
      animation: google.maps.Animation.DROP,
    });
  }

  addLocationsMarkers(items: ItemsWithCoordinates[] | any): void {
    const markers: any[] = [];
    items.forEach((location: ItemsWithCoordinates) => {
      if (!location.longitude && !location.latitude) {
        return;
      }
      let marker: any = this.getMarkerLocationConfig(location);
      marker = this._addMarker(marker);
      this.addInfo(marker, location);
      markers.push(marker);
    });
    this._clustererConfig(markers);
  }

  private getMarkerLocationConfig(location: ItemsWithCoordinates): any {
    return {
      position: { lat: +location.latitude, lng: +location.longitude },
      map: this.mapReference,
      animation: google.maps.Animation.DROP,
      icon: this.pinMarker(),
      label: '',
    };
  }

  /**
   * configurações do Cluster
   * (docs): https://developers.google.com/maps/documentation/javascript/marker-clustering
   */
  private _clustererConfig(markers: Marker[]): void {
    this._oms.removeAllMarkers();
    if (this._markerClusterer) {
      this._markerClusterer.clearMarkers();
    }

    const markersList: any[] = [];
    const styles: any[] = this.markerConfig();

    markers.forEach((marker: any) => {
      this._oms.addMarker(marker);
      markersList.push(marker); // adds the marker to the spiderfier _and_ the map
    });

    this._markerClusterer = new MarkerClusterer(
      this.mapReference,
      markersList,
      {
        imagePath: this._pinPurple,
        styles,
        maxZoom: 15,
      },
    );
  }

  private markerConfig(): any[] {
    const { primary_color } = this.store.selectSnapshot<Colors>(
      AppDefState.getColors,
    );
    const styles: any[] = [
      {
        url: this.pinMarkerGroup(primary_color),
        width: 26,
        height: 32,
        textColor: 'white',
        textSize: 16,
        averageCenter: true,
      },
    ];
    return styles;
  }

  private pinMarker(): any {
    const { primary_color } = this.store.selectSnapshot<Colors>(
      AppDefState.getColors,
    );

    return {
      path:
        // tslint:disable-next-line: max-line-length
        'M10.24 13.527a3.384 3.384 0 0 1 0-6.766 3.384 3.384 0 0 1 0 6.766zm0-12.856a9.466 9.466 0 0 0-9.473 9.473c0 7.105 9.473 17.593 9.473 17.593s9.473-10.488 9.473-17.593A9.466 9.466 0 0 0 10.24.671z',
      fillColor: primary_color,
      fillOpacity: 1,
      scale: 1.1,
      size: new google.maps.Size(33, 33),
      strokeWeight: 0,
    };
  }

  /**
   * pín group with primary color
   * @returns string
   */
  private pinMarkerGroup(primaryColor: string): string {
    const encoded: string = window.btoa(
      '<svg xmlns="http://www.w3.org/2000/svg" width="48" height="56" viewBox="0 0 24 28"><g><g><path fill="' +
        primaryColor +
        '" d="M11.998.666C5.372.666-.001 6.04-.001 12.665 0 18.224 3.786 22.89 8.92 24.25l3.079 3.08 3.08-3.08c5.132-1.36 8.918-6.026 8.918-11.585 0-6.626-5.373-11.999-11.998-11.999z"/></g></g></svg>',
    );

    return 'data:image/svg+xml;base64,' + encoded;
  }

  /**
   * Adiciona ao marcador o evento de click, para notificar o Subject
   */
  addInfo(marker: any, item?: any): void {
    let iw: any = new google.maps.InfoWindow();

    this._oms.addMarker(marker);
    google.maps.event.addListener(marker, 'spider_click', (e: any) => {
      // 'spider_click', not plain 'click'
      this.selectPin = item;
      this._centerPin(marker);
    });
  }

  /**
   * Setter que notifica o Subject
   */
  set selectPin(value: any | null) {
    this.pinSelected.next(value);
  }

  /**
   * Getter que escuta as mudanças do pinSelecionado no mapa
   * retorna uma Observable<any>
   */
  get pinSelected$(): Observable<any> {
    return this.pinSelected.asObservable();
  }

  /**
   *  abre os maps disponiveis usando as coordenadas de um item, que deve
   * conter as propriedades lat e long
   */
  openAvailableMaps(item: List): void {
    const destination: string = `${item.latitude},${item.longitude}`;

    if (!this.platform.is('capacitor') || !this.platform.is('cordova')) {
      window.open(`https://www.google.com.br/maps/dir//${destination}/`);
      return;
    }

    if (this.platform.is('ios')) {
      window.open(`maps://?q=${destination}`, '_system');
      return;
    }
    if (this.platform.is('android')) {
      window.open(`geo:?q=${destination}`, '_system');
      return;
    }
  }

  /**
   * centraliza o marker no mapa
   * @returns void
   */
  private _centerPin(marker: any): void {
    this.mapReference.panTo(marker.getPosition());
    this.mapReference.setZoom(30);
  }
}
