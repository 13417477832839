<ion-header>
  <fabapp-header>
    <ion-title *ngIf="page$ | async as page">{{ page.name }}</ion-title>
  </fabapp-header>
</ion-header>

<ion-content *ngIf="(moblet$ | obsLoading | async) as moblet">
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <ion-list *emptyState="moblet">
    <ion-item *ngFor="let page of moblet$ | async;">
      <h2>{{ page.name }}</h2>
    </ion-item>
  </ion-list>
</ion-content>
<fabapp-banner-footer></fabapp-banner-footer>
