import { HttpClient } from '@angular/common/http';
import { Request } from '@core/models';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpService<T> {
  private _lastRequest: Request;
  protected url = '';

  constructor(private $http: HttpClient) {}

  public post(body: object): Observable<T> {
    return this.send('post', this.url, body);
  }

  public get(id: string): Observable<T> {
    return this.send('get', `${this.url}/${id}`);
  }

  public update(body: object, id?: string): Observable<T> {
    return this.send('put', `${this.url}${id ? '/' + id : ''}`, body);
  }

  public delete(id: string): Observable<any> {
    return this.send('delete', `${this.url}/${id}`);
  }

  public request(
    method: string,
    complementUrl: string,
    body?: object,
  ): Observable<any> {
    const endpoint: string = this.url + complementUrl;
    return this.send(method, endpoint, body);
  }

  private send(method: string, url: string, body?: object): Observable<T> {
    method = method.toLowerCase();
    this.lastRequest = { method, url, body };

    return this.$http[method](url, body);
  }

  get lastRequest(): Request {
    return this._lastRequest;
  }

  set lastRequest(request: Request) {
    this._lastRequest = request;
  }
}
