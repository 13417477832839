import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './core/guards/auth-guard.service';

// tslint:disable: typedef
const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then(m => m.AuthPageModule),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then(
        m => m.ResetPasswordPageModule,
      ),
  },
  {
    path: '',
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: 'settings',
        loadChildren: () =>
          import('./pages/settings/settings.module').then(
            m => m.SettingsPageModule,
          ),
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then(m => m.HomePageModule),
      },
      {
        path: 'album',
        loadChildren: () =>
          import('./moblets/album/album.module').then(m => m.AlbumMobletModule),
      },
      {
        path: 'catalog',
        loadChildren: () =>
          import('./moblets/catalog/catalog.module').then(
            m => m.CatalogMobletModule,
          ),
      },
      {
        path: 'blank',
        loadChildren: () =>
          import('./moblets/blank/blank.module').then(
            m => m.BlankPageMobletModule,
          ),
      },
      {
        path: 'list',
        loadChildren: () =>
          import('./moblets/list/list.module').then(m => m.ListPageModule),
      },
      {
        path: 'group',
        loadChildren: () =>
          import('./moblets/group/group.module').then(m => m.GroupMobletModule),
      },
      {
        path: 'ratings',
        loadChildren: () =>
          import('./pages/ratings/ratings.module').then(
            m => m.RatingsPageModule,
          ),
      },
      {
        path: 'twitter',
        loadChildren: () =>
          import('./moblets/twitter-hashtag/twitter.module').then(
            m => m.TwitterPageModule,
          ),
      },
      {
        path: 'web',
        loadChildren: () =>
          import('./moblets/web/web.module').then(m => m.WebPageModule),
      },
      {
        path: 'videos',
        loadChildren: () =>
          import('./moblets/videos/videos.module').then(
            m => m.VideosPageModule,
          ),
      },
      {
        path: 'rss',
        loadChildren: () =>
          import('./moblets/rss/rss.module').then(m => m.RssPageModule),
      },
      {
        path: 'podcast',
        loadChildren: () =>
          import('./moblets/podcast/podcast.module').then(
            m => m.PodcastPageModule,
          ),
      },
      {
        path: 'mercado-livre',
        loadChildren: () =>
          import('./moblets/mercado-livre/mercado-livre.module').then(
            m => m.MercadoLivrePageModule,
          ),
      },
      {
        path: 'youtube',
        loadChildren: () =>
          import('./moblets/youtube/youtube.module').then(
            m => m.YoutubePageModule,
          ),
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('./moblets/contact/contact.module').then(
            m => m.ContactPageModule,
          ),
      },
      {
        path: 'flickr',
        loadChildren: () =>
          import('./moblets/flickr/flickr.module').then(m => m.FlickrModule),
      },
      {
        path: 'mrss',
        loadChildren: () =>
          import('./moblets/mrss/mrss.module').then(m => m.MRSSModule),
      },
      {
        path: 'audio-gallery',
        loadChildren: () =>
          import('./moblets/audio-gallery/audio-gallery.module').then(
            m => m.AudioGalleryPageModule,
          ),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
  providers: [AuthGuardService],
})
export class AppRoutingModule {}
