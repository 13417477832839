import { Platform } from '@ionic/angular';
import { AppInfo } from '@core/models';

export class AdsValidationsHelper {
  constructor(protected platform: Platform) {}

  protected hasAppId({ admob_android_id, admob_ios_id }: AppInfo): boolean {
    if (this.platform.is('android')) {
      return !!admob_android_id;
    }

    if (this.platform.is('ios')) {
      return !!admob_ios_id;
    }

    return false;
  }

  /**
   * @description
   * valida se existe ID do interstitial
   * @returns boolean
   */
  protected interstitialActive({
    admob_android_id,
    admob_android_publisher_id,
    admob_ios_id,
    admob_ios_publisher_id,
  }: AppInfo): boolean {
    if (this.platform.is('android')) {
      return !!admob_android_id && !!admob_android_publisher_id;
    }

    if (this.platform.is('ios')) {
      return !!admob_ios_publisher_id && !!admob_ios_id;
    }

    return false;
  }

  /**
   * @description
   * valida se admob está ativado
   *
   * @returns boolean
   */
  protected bannerActive({
    admob_android_id,
    admob_android_banner_id,
    admob_ios_banner_id,
    admob_ios_id,
  }: AppInfo): boolean {
    if (this.platform.is('android')) {
      console.log('bannerActive admob_android_id', admob_android_id);
      console.log(
        'bannerActive admob_android_banner_id',
        admob_android_banner_id,
      );
      return !!(admob_android_id && admob_android_banner_id);
    }

    if (this.platform.is('ios')) {
      return !!(admob_ios_banner_id && admob_ios_id);
    }

    return false;
  }
}
