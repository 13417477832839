import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppApiService {
  constructor(private httpClient: HttpClient) {}

  getUserForm(lang: string = 'pt-BR'): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/users/field-settings?lang=${lang}&mode=visualization&v=2`;
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'app-id': `${window['appId']}`,
    });
    return this.httpClient.get(url, { headers });
  }
}
