import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDef, AppInfo, CampignAd } from '@core/models';
import { FabappAdsService } from '@core/services/ads/fabapp-ads/fabapp-ads.service';
import { ModalController, Platform } from '@ionic/angular';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { BannerAndModalOfDivulgation } from 'src/app/components/banner-footer/interfaces/banner.model';
import { AppDefState } from '../appdef';
import {
  LoadAds,
  RemoveListenersOfAds,
  SetAdsAsClicked,
  SetAdsAsViewed,
  ShowModalFabApp,
  ShowBanner,
} from './fabapp-ads.action';
import { Plugins } from '@capacitor/core';
const { AdMobAdvanced } = Plugins;

interface FabappAds {
  isShowingBanner: boolean;
  banner?: BannerAndModalOfDivulgation;
  modal?: BannerAndModalOfDivulgation;
}
@State<FabappAds>({
  name: 'ads',
  defaults: {
    banner: {
      image: '',
      link: '',
      showCustomBanner: false,
      imageTitle: '',
      isVideo: false,
    },
    modal: {
      image: '',
      link: '',
      showCustomBanner: false,
      imageTitle: '',
      isVideo: false,
    },
    isShowingBanner: false,
  },
})
@Injectable()
export class FabappAdsState {
  public defaultHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  private bannerAds: CampignAd[];
  private modalAd: CampignAd;

  private activeAdIndex: number = 0;
  private activeAd: CampignAd;

  private adsTimer: any;
  private interval: number = 15000;
  private subscriptions: Subscription[] = [];
  private fabappAdsService: FabappAdsService;
  constructor(
    httpClient: HttpClient,
    private store: Store,
    private platform: Platform,
    private modalCtrl: ModalController,
  ) {
    this.fabappAdsService = new FabappAdsService(httpClient, platform, store);
  }

  @Selector()
  static banner(state: FabappAds): any {
    return { ...state.banner, isVideo: this.isVideo(state.banner.image) };
  }

  @Selector()
  static modal(state: FabappAds): BannerAndModalOfDivulgation {
    return { ...state.modal, isVideo: this.isVideo(state.modal.image) };
  }

  @Selector()
  static isShowingBanner(state: FabappAds): boolean {
    return state.isShowingBanner;
  }

  /**
   * @description
   * método checa se a url tem o mimetype de video
   * @returns boolean
   */
  static isVideo(url: string): boolean {
    if (!url) {
      return false;
    }

    const type: string = url.substr(url.lastIndexOf('.'));
    const types = {
      '.mp4': true,
      '.mov': true,
    };

    if (!types[type]) {
      return false;
    }

    return types[type];
  }

  @Action(LoadAds)
  public async loadAds(ctx: StateContext<FabappAds>): Promise<void> {
    try {
      const [bannerAds, modalAds] = await this.fabappAdsService.loadAds();

      this.bannerAds = bannerAds;
      // pega a primeira campanha
      if (modalAds?.length > 0) {
        [this.modalAd] = modalAds;
        this.treatModal(ctx);
      }

      if (!this.bannerAds || this.bannerAds.length === 0) {
        return this.getBannerFromAppDef(ctx);
      }
    } catch (error) {
      console.log('Error to load Ads: ', error);
      return this.getBannerFromAppDef(ctx);
    }

    this.activeAdIndex = 0;
    this.activeAd = this.bannerAds[this.activeAdIndex];
    this.store.dispatch(new SetAdsAsViewed('banner'));
    this.listeners();
  }

  treatModal(ctx: StateContext<FabappAds>): void {
    const link: string = this.modalAd.link;
    const image: string = this.modalAd.imagePath;
    const imageTitle: string = this.modalAd.title;
    ctx.patchState({ modal: { link, image, imageTitle } });
  }

  treatBannerOfCampaign(ctx: StateContext<FabappAds>): void {
    const link: string = this.activeAd.link;
    const image: string = this.activeAd.imagePath;
    const imageTitle: string = this.activeAd.title;
    const showCustomBanner: boolean = true;
    if (showCustomBanner && image) {
      this.store.dispatch(new ShowBanner(true));
    }
    ctx.patchState({ banner: { link, image, imageTitle, showCustomBanner } });
  }

  private listeners(): void {
    this.stopInterval();
    this.startInterval();

    // Subscribe on pause i.e. background
    const pauseSubscription: Subscription = this.platform.pause.subscribe(
      () => {
        this.stopInterval();
      },
    );

    const resumeSubscription: Subscription = this.platform.resume.subscribe(
      () => {
        this.startInterval();
      },
    );
    this.subscriptions.push(pauseSubscription, resumeSubscription);
  }

  private startInterval(): void {
    if (!this.bannerAds || this.bannerAds.length <= 1) {
      return;
    }

    this.adsTimer = setInterval(() => this.nextCampaign(), this.interval);
  }

  /**
   * @description
   * caso tenha mais de uma campanha
   *
   * @returns void
   */
  private nextCampaign(): void {
    if (this.activeAdIndex >= this.bannerAds.length) {
      this.activeAdIndex = 0;
    }

    this.activeAd = Object.assign(
      {} as CampignAd,
      this.bannerAds[++this.activeAdIndex],
    );
    this.store.dispatch(new SetAdsAsViewed('banner'));
  }

  private stopInterval(): void {
    if (this.adsTimer) {
      clearInterval(this.adsTimer);
    }
  }

  /**
   * @description
   * método escuta a appDef para modificar o banner
   * @returns void
   */
  private getBannerFromAppDef(ctx: StateContext<FabappAds>): void {
    this.store
      .select(
        AppDefState.getFromAppDef(['info', 'default_status', 'show_banner']),
      )
      .subscribe((appInfo: Partial<AppDef>) =>
        this.treatBannerData(appInfo, ctx),
      );
  }

  /**
   * @description
   * trata os dados da appDef para o banner
   * @returns void
   */
  private treatBannerData(
    { info, show_banner }: Partial<AppDef>,
    ctx: StateContext<FabappAds>,
  ): void {
    const {
      enable_custom_banner,
      custom_banner_image,
      custom_banner_url,
    } = info;

    let showCustomBanner: boolean =
      enable_custom_banner || show_banner ? true : false;
    const image: string =
      String(custom_banner_image) !== '0' ? String(custom_banner_image) : null;
    const link: string = custom_banner_url;

    // banner do admob só aparece no mobile
    // plugin deve estar disponivel
    if (
      this.platform.is('capacitor') &&
      this.admobActive(info) &&
      AdMobAdvanced
    ) {
      showCustomBanner = false;
    }

    if (showCustomBanner && image) {
      this.store.dispatch(new ShowBanner(true));
    }
    ctx.patchState({ banner: { link, image, showCustomBanner } });
  }

  /**
   * @description
   * valida se admob está ativado
   *
   * @returns boolean
   */
  private admobActive({
    admob_android_id,
    admob_android_banner_id,
    admob_ios_banner_id,
    admob_ios_id,
  }: AppInfo): boolean {
    if (this.platform.is('android')) {
      return !!(admob_android_id && admob_android_banner_id);
    }

    if (this.platform.is('ios')) {
      return !!(admob_ios_banner_id && admob_ios_id);
    }

    return false;
  }

  @Action(SetAdsAsViewed)
  async setAdAsViewed(
    ctx: StateContext<FabappAds>,
    { ad }: SetAdsAsViewed,
  ): Promise<void> {
    if (ad === 'modal') {
      await this.fabappAdsService.setAdAsViewed(this.modalAd);
      return;
    }

    this.treatBannerOfCampaign(ctx);
    await this.fabappAdsService.setAdAsViewed(this.activeAd);
  }

  @Action(SetAdsAsClicked)
  async setAdAsClicked(
    ctx: StateContext<FabappAds>,
    { ad }: SetAdsAsClicked,
  ): Promise<void> {
    if (ad === 'modal') {
      await this.fabappAdsService.setAdAsClicked(this.modalAd);
      return;
    }

    await this.fabappAdsService.setAdAsClicked(this.activeAd);
  }

  @Action(RemoveListenersOfAds)
  public dispose(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe(),
    );
  }

  @Action(ShowModalFabApp)
  public async showModal(
    ctx: StateContext<FabappAds>,
    { component }: ShowModalFabApp,
  ): Promise<void> {
    const { modal } = ctx.getState();
    if (!modal?.image) {
      return;
    }

    const newModal: HTMLIonModalElement = await this.modalCtrl.create({
      component,
    });

    await newModal.present();
  }

  @Action(ShowBanner)
  showing(ctx: StateContext<FabappAds>, { isShow }: ShowBanner): void {
    ctx.patchState({ isShowingBanner: isShow });
  }
}
