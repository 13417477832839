import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '@core/services/navigation.service';
import { RegularSignUp } from '@core/state/auth';
import { ThemingColor } from '@core/types';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslocoService, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { User } from '@core/models';

const TRANSLATION_SCOPE: string = 'signup';
interface ErrorSignup {
  genericError: string;
  alreadyExists: {
    email: string;
    phone_number: string;
  };
  registeredSuccessfully: string;
}

@Component({
  selector: 'app-signup',
  templateUrl: './signup.page.html',
  styleUrls: ['./signup.page.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: TRANSLATION_SCOPE,
    },
  ],
})
export class SignupPage implements OnInit {
  @Input() isModal = false;
  @Input() intentUrl: string;
  isSubmitting = false;
  loading = true;

  translations: ErrorSignup;
  translationSubscription: Subscription[] = [];
  constructor(
    private store: Store,
    private navigationService: NavigationService,
    private toast: ToastController,
    public modalController: ModalController,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.loadTranslation();
  }

  ionViewWillLeave(): void {
    this.translationSubscription.forEach((subscription: Subscription) =>
      subscription.unsubscribe(),
    );
  }

  loadTranslation(): void {
    const subscription: Subscription = this.translocoService
      .selectTranslation(
        `${TRANSLATION_SCOPE}/${this.translocoService.getActiveLang()}`,
      )
      .subscribe((tradutions: ErrorSignup) => {
        this.translations = tradutions;
      });

    this.translationSubscription.push(subscription);
  }

  async formSubmit(form: User): Promise<void> {
    try {
      this.isSubmitting = true;

      const data: User = form;
      data['phone_number'] = data['phone_number'].replace(/[^\d]+/g, '');
      data['document_number'] = data['document_number'].replace(/[^\d]+/g, '');

      await this.store.dispatch(new RegularSignUp(data)).toPromise();

      this.feedbackToaster(this.translations.registeredSuccessfully, 'success');

      if (this.isModal) {
        this.modalController.dismiss({ navigate: true });
      } else {
        this.navigationService.returnToHomePage();
      }
    } catch (err) {
      // to do: remover quando tiver interceptor de erros
      this.treatError(err);
      console.error(err);
    } finally {
      this.isSubmitting = false;
    }
  }

  private async feedbackToaster(
    message: string,
    theme: ThemingColor = 'danger',
  ): Promise<void> {
    const toast: HTMLIonToastElement = await this.toast.create({
      message,
      color: theme,
      position: 'top',
      buttons: [
        {
          text: 'ok',
        },
      ],
    });
    toast.present();
  }

  treatError(error: any): void {
    if (error?.status !== 422 || !Object.keys(error.error)[0]) {
      this.feedbackToaster(this.translations.genericError, 'warning');
      return;
    }

    const message: string = Object.keys(error.error)[0];
    this.feedbackToaster(this.translations.alreadyExists[message], 'warning');
  }
}
