import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
  SafeStyle,
  SafeResourceUrl,
} from '@angular/platform-browser';

@Pipe({
  name: 'safe',
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string, type?: string): SafeStyle | SafeResourceUrl {
    if (type === 'style') {
      return this.sanitizer.bypassSecurityTrustStyle(url);
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
