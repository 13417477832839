import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '@core/services/navigation.service';
import { Store } from '@ngxs/store';
import { Page } from '@core/models';
import { AppDefState } from '@core/state/appdef';
import { Observable } from 'rxjs';
import { GroupMobletService } from './group.service';

@Component({
  selector: 'group-moblet',
  templateUrl: './group.page.html',
  styleUrls: ['./group.page.scss'],
  providers: [GroupMobletService],
})
export class GroupMobletPage implements OnInit {
  // TODO: Tipar com moblets
  public moblet$: Observable<any>;
  public page$: Observable<Page>;
  public shouldGoBackToHome = false;

  constructor(
    private groupMobletService: GroupMobletService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    public navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    const id: number = +this.route.snapshot.params.id;

    // TODO tipar
    this.moblet$ = this.groupMobletService.moblet;
    this.shouldGoBackToHome = !this.router.navigated;
    this.page$ = this.store.select(AppDefState.getPage(id));
    this.groupMobletService.loadMoblet(id);
  }

  async doRefresh(event: any): Promise<void> {
    const id: number = +this.route.snapshot.params.id;
    await this.groupMobletService.loadMoblet(id);
    event.target.complete();
  }
}
