import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from '@core/core.module';
import { appDefInitializer } from '@core/initializers/appdef.initializer';
import { AppIdInterceptor } from '@core/interceptors/appId.interceptor';
import { JwtInterceptor } from '@core/interceptors/jwt.interceptor';
import { AppDefState } from '@core/state/appdef';
import { AuthState } from '@core/state/auth';
import { CoreState } from '@core/state/core/core.state';
import { MobletsState } from '@core/state/moblets';
import { environment } from '@environments/environment';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import {
  TranslocoConfig,
  TranslocoModule,
  TRANSLOCO_CONFIG,
  TRANSLOCO_SCOPE,
} from '@ngneat/transloco';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationState } from './core/state/navigation/navigation.state';
import { translocoLoader } from './translocoLoader';
import { FabappAdsState } from '@core/state/ads';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    AppRoutingModule,
    IonicModule.forRoot(),
    NgxsModule.forRoot(
      [
        AppDefState,
        MobletsState,
        CoreState,
        AuthState,
        NavigationState,
        FabappAdsState,
      ],
      {
        developmentMode: !environment.production,
      },
    ),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: ['auth', 'appdef.info', 'appdef.pages', 'appdef.flattened_pages'],
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    TranslocoModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appDefInitializer,
      deps: [Store],
      multi: true,
    },
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        defaultLang: 'pt-BR',
        fallbackLang: ['pt-BR', 'en-US', 'es-ES'],
        listenToLangChange: true,
        prodMode: environment.production,
      } as TranslocoConfig,
    },
    translocoLoader,
    { provide: TRANSLOCO_SCOPE, useValue: 'shared' },
    { provide: HTTP_INTERCEPTORS, useClass: AppIdInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
