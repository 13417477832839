import { User } from '@core/models';
import { AuthState } from '@core/state/auth';
import { Store } from '@ngxs/store';
import { findObjectKeyRecursive } from '@utils';

export class TemplateParser {
  constructor(private store: Store) {}

  public parse(url: string): string {
    if (!url) {
      return '';
    }

    const user: User = this.store.selectSnapshot(AuthState.getUser);
    if (!user) {
      return url;
    }

    return this.templateParser(url, {
      user,
    });
  }

  private templateParser(value: string, context: any): string {
    let content: string = value;

    if (!content || !context) {
      return content;
    }

    const parameters: string[] = content.match(/{{([^}]+)}}/g);

    if (parameters && parameters.length > 0) {
      parameters.map((val: string) => {
        const clean: string = val.replace(/{{|}}/g, '').trim();
        const fallbackValue: string = clean.includes('|')
          ? clean.split('|')[1]
          : val;
        const splitted: string[] = clean.includes('|')
          ? clean.split('|')[0].split('.')
          : clean.split('.');

        if (splitted.length > 1) {
          content = content.replace(
            val,
            findObjectKeyRecursive(context, splitted) || fallbackValue,
          );
        } else {
          content = content.replace(val, fallbackValue);
        }
      });
    }

    return content;
  }
}
