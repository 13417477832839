export const environment: any = {
  production: false,
  hmr: false,
  loftPortarias: 778015,
  useTermsUrl: `https://politicas.applink.com.br/${window['appId']}/terms-conditions`,
  privacyPolicyUrl: `https://politicas.applink.com.br/${window['appId']}/privacy-policy`,
  nauva: {
    url: 'https://nauva.dev.universo.mobi',
    clientId: '205ae9f827b696c16880713bdf7c87',
  },
  appApi: {
    url: 'https://proxy.dev.fabricadeaplicativos.com.br/applications',
    url2: '//ajax.dev.fabricadeaplicativos.com.br/application/',
  },
  mobletApi: {
    url: 'https://proxy.dev.universo.mobi/moblets',
  },
  daia: {
    url: 'https://daia.dev.fabricadeaplicativos.com.br',
  },
  maps: {
    key: 'AIzaSyD17a1iuTqucVzjCTC2jlP6UoCGI3x9W_s',
    url: 'https://maps.googleapis.com/maps/api/',
  },
  rewards: {
    uri: 'https://rewards.dev.fabricadeaplicativos.com.br/api/app',
  },
  hera: {
    url: 'https://hera.dev.ulmo.fabapp.com',
    authorization:
      // tslint:disable-next-line: max-line-length
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjYxZGJhMTk4Zjk3NWMzYTNjNWIzMDZiZTg5ZGY0N2FhZWY4MzYxOTBlNWZiNTQwY2JhYTI1MmI4YjUwYmM3ODk3OTBhZTcyZmY1ZGNhMTExIn0.eyJhdWQiOiIyIiwianRpIjoiNjFkYmExOThmOTc1YzNhM2M1YjMwNmJlODlkZjQ3YWFlZjgzNjE5MGU1ZmI1NDBjYmFhMjUyYjhiNTBiYzc4OTc5MGFlNzJmZjVkY2ExMTEiLCJpYXQiOjE1NDg5MDA1NDUsIm5iZiI6MTU0ODkwMDU0NSwiZXhwIjozMzEwNTgwOTM0NCwic3ViIjoiIiwic2NvcGVzIjpbXX0.kAf4VlcR3BuWUK-WrANOhgJhbj_EwD3A5-JN_eB7WHy4QxDqqNfV9bjO9dErJ12gs0sPICjVXLW5SbHj8kudVagO_VuB9GpKDSQKsORZwYzQtzj1yxukUxNaMDNq1Vg17dpAnuqn_cDpoNNwQHSPfVXtEVJP4RYG6H-_-AhpRVrcyJjsGUX8bKWInMNEs28jwpwysp9-_08vNBOLBTC1KYnmIzLhdZMcn4UxTqaNcjWxdP7FDLnYf4lY7q43Hbdw5kxpaZvItpyEYXtTFI_Rlj5JAy0KG5qVJJbd1810owEKwcKv779SJH9oMsPcfPmNsMTK2dfvagin7gTGOJPITcTFIJUzGhxf4w02XvL1bun-BnYxtaqmQtlDGyVTaZ1w7WYwNo9344lHGBv1w45EZGmC9bqWRAK7dPzv_rBfV8Cs6aIHVry0laJiKYarqVJj1cvNW6i6LIG7WIq1rDn2zxoWfvMucA6g0zwtnFNjMu61vHkHgai9bZhkH_FuHmNxJQjRgHvt2qot2T8n3QoZBBCO9k3RbW1b2kDhNd0SpEv1jdFS0yrmg1iV32G4vCvCXJNLCg0yCC3YnlWxMFQWryJkHQ_YviQXmXrjb9wdDLDCBF79ESDTQgY_IUWEwAqSY2dVeNfXWObkUgTC2-BRScSVlvIYqZGYuXhBUj6PmGM',
  },
  hermes: {
    url: 'https://hermes.dev.ulmo.fabapp.com',
  },
  formsApi: {
    url: 'https://forms.dev.ulmo.fabapp.com',
  },
  aphroditeApi: {
    url: 'https://aphrodite.dev.ulmo.fabapp.com',
    noCacheUrl: 'https://aphrodite.dev.ulmo.fabapp.com',
  },
  hefestoApi: {
    url: 'https://hefesto.dev.ulmo.fabapp.com',
  },
  heimdallApi: {
    url: 'https://heimdall.dev.ulmo.fabapp.com',
  },
};
