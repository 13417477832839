import { RssPage } from '../moblets/rss/rss.page';

export function getRouteNameBySuperClass(superClass: string): string {
  switch (superClass) {
    case 'm-blank-page':
      return '/blank/';
    case 'm-album':
      return '/album/';
    case 'folder':
      return '/group/';
    case 'lists':
      return '/list/';
    case 'm-catalog':
      return '/catalog/';
    case 'twitter_hashtag':
    case 'twitter':
      return '/twitter/';
    case 'uframe':
    case 'm-tawkto':
    case 'iframe':
      return '/web/';
    case 'videoupload':
      return '/videos/';
    case 'rss':
      return '/rss/';
    case 'podcast':
      return '/podcast/';
    case 'mercado_livre':
      return '/mercado-livre/';
    case 'youtube':
      return '/youtube/';
    case 'contact':
      return '/contact/';
    case 'flickr':
      return '/flickr/';
    case 'mrss':
      return '/mrss/';
    case 'audioupload':
      return '/audio-gallery/';
    default:
      // TODO: pagina de atualizacao do app
      return null;
  }
}
