export class LoadMoblet {
  static readonly type = '[moblet] Load Moblet';
  constructor(
    public id: number,
    public page: number = 1,
    public version: number = 2,
    public extraParams?: object,
  ) {}
}

export class LoadGroup {
  static readonly type = '[moblet] Load Group';
  constructor(public id: number) {}
}

export class LoadMobletItem {
  static readonly type = '[moblet] Load Moblet Item';
  constructor(public id: any, public item: any) {}
}
