import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '@core/services/navigation.service';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppDefState } from '@core/state/appdef';
import { AppDef } from '@core/models';
import { SearchPageComponent } from '../search/search-page.component';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'fabapp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  shouldGoBackToHome = false;
  @Select(
    AppDefState.getFromAppDef(['info', 'colors', 'layout', 'logo', 'search']),
  )
  partialAppDef$: Observable<Partial<AppDef>>;
  openSearch: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public router: Router,
    public navigationService: NavigationService,
    private modalCtrl: ModalController,
  ) {}

  ngOnInit(): void {
    this.shouldGoBackToHome = !this.router.navigated;
  }

  async openSearchModal(animated: boolean = true): Promise<void> {
    const modal: HTMLIonModalElement = await this.modalCtrl.create({
      animated,
      component: SearchPageComponent,
    });

    return await modal.present();
  }
}
