import { Pipe, PipeTransform } from '@angular/core';
import { Platform } from '@ionic/angular';

@Pipe({
  name: 'getUrl',
})
export class GetUrlPipe implements PipeTransform {
  constructor(private platform: Platform) {}

  transform(htmlContent: string, ...args: any[]): any {
    const target: string = this.platform.is('mobile') ? '_system' : '_blank';
    const a = htmlContent.split(' ');

    for (let i = 0; i < a.length; i++) {
      if (a[i].startsWith('https')) {
        const url: string = a[i];
        htmlContent = htmlContent.replace(
          url,
          (url: string) =>
            `<a onclick="window.open('${url}', '${target}')">${url}</a>`,
        );
      }
    }
    return htmlContent;
  }
}
