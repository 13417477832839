import { Component } from '@angular/core';
import { Plugins, StatusBarAnimation } from '@capacitor/core';
import { AppDef, Colors, Page, User } from '@core/models';
import { AdmobAdsService } from '@core/services/ads';
import { NavigationService } from '@core/services/navigation.service';
import { LoadAds } from '@core/state/ads';
import { AppDefState } from '@core/state/appdef/';
import { AuthState, RefreshToken } from '@core/state/auth';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { Select, Store } from '@ngxs/store';
import { setCssVariables } from '@utils';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
const { StatusBar, SplashScreen } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @Select(AppDefState.getPages) pages$: Observable<Page[]>;
  @Select(AppDefState.getFromAppDef(['info']))
  partialAppDef$: Observable<Partial<AppDef>>;
  @Select(AuthState.getUser) user$: Observable<User>;
  language = window.navigator['userLanguage'] || window.navigator.language;
  colorsSubscription: Subscription;

  constructor(
    private platform: Platform,
    private navCtrl: NavController,
    private store: Store,
    private navigationService: NavigationService,
    private menu: MenuController,
    private translocoService: TranslocoService,
    private adsService: AdmobAdsService,
  ) {
    this.initializeApp();
    this.translocoService.setActiveLang(
      this.language ? this.language : 'pt-BR',
    );
  }

  initializeApp(): void {
    this.platform.ready().then(async () => {
      this.store.dispatch(new RefreshToken());
      this.setColorsSubscription();
      window.addEventListener('keyboardWillShow', () => {
        document.body.classList.add('keyboard-is-open');
      });
      window.addEventListener('keyboardWillHide', () => {
        document.body.classList.remove('keyboard-is-open');
      });
      this.adsService.initialize();
      this.init();
    });
  }

  /**
   * @description
   * timeOut porque o app.component não tem o evento ionViewDidEnter()
   *
   * @returns void
   */
  init(): void {
    setTimeout(async () => {
      await this.hideSplashScreen();
      this.hideSplashScreenWeb();
    }, 3000);
  }

  ngOnInit(): void {
    this.store.dispatch(new LoadAds()).toPromise();
  }

  setColorsSubscription(): void {
    this.colorsSubscription = this.store
      .select(AppDefState.getColors)
      .pipe(filter((colors: Colors) => !!colors))
      .subscribe((colors: Colors) => {
        setCssVariables(colors);
      });
  }

  login(): void {
    this.navCtrl.navigateForward('/auth/login');
    this.menu.close();
  }

  goSettings(): void {
    this.navCtrl.navigateForward('/settings');
    this.menu.close();
  }

  setHomePage(): void {
    this.navigationService.returnToHomePage();
  }

  navigate(page: Page): void {
    this.navigationService.navigate({ instanceId: +page.instance.id });
  }

  async hideSplashScreen(): Promise<void> {
    if (this.platform.is('capacitor')) {
      await StatusBar.show({
        animation: StatusBarAnimation.Fade,
      });
      await SplashScreen.hide();
    }
  }

  hideSplashScreenWeb(): void {
    const splash: Element = document.querySelector('#splashscreen-home');
    splash.classList.remove('-show');
  }

  trackByIndex(index: number, item: Page): number {
    return item.id ? item.id : index;
  }
}
