import { AdPosition, AdSize, BannerAdOptions } from 'capacitor-admob-advanced';

export class BannerBuilder {
  private _adIdAndroidBanner: string = '';
  private _adIdIosBanner: string = '';
  private _isTesting: boolean = false;

  build(): BannerAdOptions {
    return {
      adIdAndroid: this._adIdAndroidBanner,
      adIdIos: this._adIdIosBanner,
      adSize: AdSize.SMART_BANNER,
      adPosition: AdPosition.BOTTOM,
      isTesting: this._isTesting,
      topMargin: 0,
      bottomMargin: 0,
    };
  }

  public isTesting(value: boolean): this {
    this._isTesting = value;
    return this;
  }

  public adIdAndroidBanner(value: string): this {
    this._adIdAndroidBanner = value;
    return this;
  }

  public adIdIosBanner(value: string): this {
    this._adIdIosBanner = value;
    return this;
  }
}
