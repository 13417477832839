import {
  PhoneNumber,
  PhoneNumberType,
  PhoneNumberUtil,
} from 'google-libphonenumber';

export class PhoneHelper {
  private _mask: string = '99 99999-9999';
  /**
   * @description
   * '99 99999-9999' -> default mask
   */
  public get mask(): string {
    return this._mask;
  }
  public set mask(value: string) {
    this._mask = value;
  }

  private _ddi: number = 55;
  /**
   * @description
   * default DDI -> 55
   */
  public get ddi(): number {
    return this._ddi;
  }
  public set ddi(value: number) {
    this._ddi = value;
  }

  static getMask(regionCode: string): string {
    const exampleNumber: PhoneNumber = PhoneNumberUtil.getInstance().getExampleNumberForType(
      regionCode,
      PhoneNumberType.MOBILE,
    );

    if (!exampleNumber) {
      return '';
    }

    const exampleNumberFormated: string = PhoneNumberUtil.getInstance().format(
      exampleNumber,
      PhoneNumberUtil.getInstance()['INTERNATIONAL'],
    );

    return exampleNumberFormated.replace(/\d/g, '9');
  }

  /**
   * @description
   *  adiciona o sinal '+' no telefone com ddi,
   *  trata o telefone com o método do PhoneNumberUtil
   *
   * @param  {string} value
   * Regex /[^\d]+/g -> retira caracteres especiais e espaco
   *
   * @returns string
   *  getNational() retorna telefone sem o ddi
   *
   */
  public treatPhone(value: string): string {
    if (!value) {
      return '';
    }

    value = value.replace(/[^\d]+/g, '');

    value = '+' + value;

    const uti: PhoneNumberUtil = PhoneNumberUtil.getInstance();
    const phone: PhoneNumber = uti.parseAndKeepRawInput(value);
    // extraí o ddi do telefone
    this.ddi = phone.getCountryCode();

    // seta uma máscara usando o ddi
    this.mask = PhoneHelper.getMask(uti.getRegionCodeForCountryCode(this.ddi));
    // retorna o número sem o ddi
    return phone.getNationalNumber().toString();
  }

  /**
   * @description
   * concatena DDI com telefone
   * retirando espacos e caracteres especiais
   * @returns void
   */
  public phoneWithDDI(phone: string): string {
    if (!phone) {
      return '';
    }

    return `${this.ddi}${phone}`.replace(/[^\d]+/g, '');
  }
}
