import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AppDef } from '@core/models';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({ providedIn: 'root' })
export class AppDefService extends HttpService<AppDef> {
  loadAppDef(): Observable<AppDef> {
    return this.request(
      'get',
      `${environment.appApi.url}/${window['appId']}.json?preview=true`,
    );
  }
}
