import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
  appId: string;
  constructor(private httpClient: HttpClient) {
    if (window['appId']) {
      this.appId = window['appId'].toString();
    }
  }

  login({ email, password }): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/v2/auth/login`;
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'app-id': this.appId,
    });

    return this.httpClient.post<any>(url, { email, password }, { headers });
  }

  logout(): Observable<any> {
    return of(() => {
      return true;
    });
  }

  refreshToken({ refresh_token }): Observable<any> {
    const url = `${environment.hera.url}/app/auth/refresh`;
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'app-id': this.appId,
    });

    return this.httpClient.post<any>(url, { refresh_token }, { headers });
  }
}
