import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AppLayout, Page, DynamicFormField, CountryDDI } from '@core/models';
import { getRouteNameBySuperClass } from '@utils';
import { AppDefState } from '../appdef';
import { SetHomeRoute, LoadSignupForm, LoadCountriesDDI } from './core.actions';
import { AppApiService } from '@core/services/app-api.service';
import { HeraService } from '@core/services/hera.service';

export interface CoreStateModel {
  homeRoute: (string | number)[];
  form: DynamicFormField[];
  countries: CountryDDI[];
}

@State<CoreStateModel>({
  name: 'core',
  defaults: {
    homeRoute: ['/'],
    form: null,
    countries: [],
  },
})
@Injectable()
export class CoreState {
  constructor(
    private store: Store,
    private appApiService: AppApiService,
    private heraService: HeraService,
  ) {}

  @Selector()
  static getHomeRoute(state: CoreStateModel): (string | number)[] {
    return state.homeRoute;
  }

  @Selector()
  static getCountriesList(state: CoreStateModel): CountryDDI[] {
    return state.countries;
  }

  @Selector()
  static getDynamicForm(state: CoreStateModel): DynamicFormField[] {
    return state.form;
  }

  @Action(SetHomeRoute)
  setHomeRoute(ctx: StateContext<CoreStateModel>): any {
    const state: CoreStateModel = ctx.getState();
    const layout: AppLayout = this.store.selectSnapshot(AppDefState.getLayout);
    const pages: Page[] = this.store.selectSnapshot(AppDefState.getPages);

    if (layout.layout_type !== 'page_home') {
      return ctx.patchState({
        homeRoute: ['/'],
      });
    }

    const instanceId: number = pages[0].moblets[0].instance.id;
    const superClass: string = this.store.selectSnapshot(
      AppDefState.getPage(instanceId),
    ).moblet_name;
    const routeName: string = getRouteNameBySuperClass(superClass);

    return ctx.patchState({
      homeRoute: [routeName, instanceId],
    });
  }

  @Action(LoadSignupForm)
  async loadSignupForm(ctx: StateContext<CoreStateModel>): Promise<any> {
    const state: CoreStateModel = ctx.getState();
    const userForm: DynamicFormField[] = await this.appApiService
      .getUserForm()
      .toPromise();

    ctx.patchState({
      form: userForm,
    });
  }

  @Action(LoadCountriesDDI)
  async loadCountriesDDI(ctx: StateContext<CoreStateModel>): Promise<any> {
    const state: CoreStateModel = ctx.getState();
    const countries: CountryDDI[] = await this.heraService
      .getCountriesInfo()
      .toPromise();

    ctx.patchState({
      countries,
    });
  }
}
