import { Injectable } from '@angular/core';
import { Page } from '@core/models';
import { LoadAppDef } from '@core/state/appdef';
import { LoadGroup } from '@core/state/moblets';
import { Observable } from 'rxjs';
import { filter, pluck } from 'rxjs/operators';
import { BaseMobletService } from '../base-moblet.service';

@Injectable()
export class GroupMobletService extends BaseMobletService<Page> {
  get moblet(): Observable<Page[]> {
    return this._moblet.asObservable().pipe(
      filter((data: any) => data),
      pluck<Page[], Page[]>('entries'),
    );
  }

  get layout(): Observable<any> {
    return this._moblet.asObservable().pipe(
      filter((data: any) => data),
      pluck('layout'),
    );
  }

  async loadMoblet(id: number): Promise<void> {
    this._mobletId = id;
    await this.store
      .dispatch([new LoadAppDef(), new LoadGroup(id)])
      .toPromise();

    if (!this._mobletSubscription) {
      this.setMobletSubscription();
    }
  }
}
