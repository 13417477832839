<ng-container *ngIf="partialAppDef$ | async as partialAppDef">
  <ng-container *isHomePage="'menu'">
    <ng-container *ngIf="partialAppDef?.layout?.show_header">
      <ion-toolbar
        [style.--background]="
          partialAppDef.info.headerImage
            ? partialAppDef.info.headerImage
            : partialAppDef.colors.header_color
        "
        [style.--color]="partialAppDef.colors.navigation_color"
        mode="md"
      >
        <ion-buttons
          slot="start"
          [style.--color]="partialAppDef.colors.navigation_color"
        >
          <ion-menu-button
            [style.--color]="partialAppDef.colors.navigation_color"
          ></ion-menu-button>
        </ion-buttons>

        <ion-title
          class="header-title"
          [slot]="partialAppDef.layout.header_logo_alignment"
          [class.-center]="partialAppDef.layout.header_logo_alignment === ''"
          [class.-rightbutton]="
            partialAppDef.search &&
            partialAppDef.layout.search_field === 'header'
          "
        >
          <img
            *ngIf="partialAppDef.logo; else appName"
            class="header-logo"
            [src]="partialAppDef.logo"
            [alt]="partialAppDef.info.name"
          />
          <ng-template #appName>
            {{ partialAppDef.info.name }}
          </ng-template>
        </ion-title>

        <ion-buttons
          slot="end"
          *ngIf="
            partialAppDef.search &&
            partialAppDef.layout.search_field === 'header'
          "
        >
          <ion-button [style.--color]="partialAppDef.colors.navigation_color">
            <ion-icon slot="icon-only" name="search"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>

      <ion-toolbar
        *ngIf="
          partialAppDef?.layout?.search_field === 'top' && partialAppDef?.search
        "
        [style.--background]="partialAppDef?.colors?.header_color"
      >
        <ion-searchbar
          class="fake-search"
          [style.--background]="'#fff'"
          (click)="openSearchModal(false)"
        >
        </ion-searchbar>
      </ion-toolbar>
    </ng-container>
  </ng-container>

  <ion-toolbar *isHomePage="'back'" color="primary">
    <ion-buttons slot="start">
      <ion-back-button [defaultHref]="navigationService.getHomeRoute()">
      </ion-back-button>
    </ion-buttons>
    <ng-content select="ion-title"></ng-content>
    <ng-content select="ion-buttons"></ng-content>
  </ion-toolbar>
</ng-container>
