import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { AppDef } from '@core/models';
import { ModalController, IonSearchbar } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';

@Component({
  selector: 'fabapp-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
})
export class SearchPageComponent implements AfterViewInit {
  @Input() partialAppedef: Partial<AppDef>;
  @ViewChild(IonSearchbar) searchBarRef: IonSearchbar;
  results = null;
  loading = false;

  searchTitle: Observable<string>;
  constructor(
    private modalCtrl: ModalController,
    translocoService: TranslocoService,
  ) {
    this.searchTitle = translocoService.selectTranslate(
      'searchTitle',
      {},
      `search/${translocoService.getActiveLang()}`,
    );
  }

  ngAfterViewInit(): void {
    // Gargalo para dar focus no input
    // sem não dar null pointer
    setTimeout(async () => {
      if (this.searchBarRef) {
        await this.searchBarRef.setFocus();
      }
    }, 300);
  }

  dismiss(): void {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }
}
