import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Plugins } from '@capacitor/core';
import { AppInfo } from '@core/models';
import { NavigationService } from '@core/services/navigation.service';
import { AppDefState } from '@core/state/appdef';
import { Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { BannerAdOptions } from 'capacitor-admob-advanced';
import { filter } from 'rxjs/operators';
import { BannerBuilder } from './banner-builder';
import { AdsValidationsHelper } from './ads-validations-helper';
import { ShowModalFabApp } from '@core/state/ads';
import { DivulgationModalComponent } from 'src/app/components/divulgation-modal/divulgation-modal.component';
import { ShowBanner } from '@core/state/ads/fabapp-ads.action';
const { AdMobAdvanced, Toast } = Plugins;

type BannerActions = 'hide' | 'resume' | 'show' | 'remove';
type InterstitialActions = 'load' | 'show';

@Injectable({
  providedIn: 'root',
})
export class AdmobAdsService extends AdsValidationsHelper {
  private routesForHide: string[] = [
    '/auth',
    '/reset-password',
    '/settings',
    '/ratings',
  ];
  private info: AppInfo;
  private canshowBannerAdmob: boolean = false;
  private shouldNotShowAd: boolean = false;
  private canShowInterstitial: boolean = false;
  public index: number = 0;
  private failedToShowBanner: boolean = false;

  constructor(
    private store: Store,
    protected platform: Platform,
    private router: Router,
    private navigationService: NavigationService,
  ) {
    super(platform);
    this.routeEventsListener();
    this.admobListerners();
  }

  /**
   * @description
   * para inicializar o admob é necessário ter
   * id da plataforma (ios/android) e
   * seus respectivos banners
   *
   * @returns void
   */
  public async initialize(): Promise<boolean> {
    const info: AppInfo = this.getInfoAdmob();
    // se não tiver o plugin disponivel retorna
    if (!info || !AdMobAdvanced) {
      return;
    }

    try {
      return this.loadAds();
    } catch (error) {
      console.log('Cannot init admob: ', error);
      this.errorToLoad();
    }
  }

  private async loadAds(): Promise<boolean> {
    try {
      const { value } = await AdMobAdvanced.initialize({
        appIdAndroid: this.info.admob_android_id,
        appIdIos: this.info.admob_ios_id,
      });

      this.loadInterstitial();
      this.showBanner();
      return value;
    } catch (error) {
      throw error;
    }
  }

  private getInfoAdmob(): AppInfo {
    const { info } = this.store.selectSnapshot(
      AppDefState.getFromAppDef(['info', 'default_status']),
    );

    this.info = info;
    if (!this.hasAppId(info)) {
      return null;
    }

    this.canshowBannerAdmob = this.bannerActive(info);
    this.canShowInterstitial = this.interstitialActive(info);
    this.shouldNotShowAd =
      (!this.canShowInterstitial && !this.canshowBannerAdmob) ||
      !this.platform.is('capacitor');

    if (this.shouldNotShowAd) {
      return null;
    }

    return info;
  }

  private async loadInterstitial(): Promise<void> {
    return await this.interstitialActions('load');
  }

  /**
   * @description
   * after show interstitial load other interstitial
   */
  private async showInterstitial(): Promise<void> {
    if (!AdMobAdvanced) {
      return;
    }

    try {
      await this.interstitialActions('show');
      await this.loadInterstitial();
    } catch (error) {
      console.log('Cannot show interstitital:', error);
    }
  }

  private async showBanner(): Promise<void> {
    try {
      const result: any = await this.bannerActions('show');
      return result;
    } catch (error) {
      console.log('Cannot show banner: ', error);
      throw error;
    }
  }

  public async resumeBanner(): Promise<void> {
    return await this.bannerActions('resume');
  }

  /**
   * @description
   * se houve uma falha anteriomente
   * o banner já foi removido
   * logo não é possivel chamar o hide
   * por isso o:
   * if (this.failedToShowBanner) { return; }
   * @returns Promise
   */
  public async hideBanner(): Promise<void> {
    if (this.failedToShowBanner) {
      return;
    }

    return await this.bannerActions('hide');
  }

  /**
   * @description
   * remove instance of banner
   * @returns Promise
   */
  private async removeBanner(): Promise<void> {
    return await this.bannerActions('remove');
  }

  /**
   * @description
   * apresenta toast com erro genérico,
   * sendo apresentado apenas no preview
   * @returns Promise
   */
  private async errorToLoad(errorCode?: number): Promise<void> {
    if (!window['preview'] && this.platform.is('capacitor')) {
      return;
    }

    await Toast.show({
      text: 'Os anúncios do Admob só apareceram no compilado',
      duration: 'long',
      position: 'bottom',
    });
    return;
  }

  private admobListerners(): void {
    if (!this.platform.is('capacitor') || !AdMobAdvanced) {
      return;
    }

    AdMobAdvanced.addListener('onBannerAdFailedToLoad', (info: any) => {
      this.errorToLoad();
      this.store.dispatch(new ShowBanner(false));
      this.failedToShowBanner = true;
      this.removeBanner();
    });
  }

  private async interstitialActions(
    action: InterstitialActions,
  ): Promise<void> {
    if (!this.canShowInterstitial || !this.platform.is('capacitor')) {
      return;
    }

    let options: any;
    if (action === 'load') {
      // TODO: retirar testing true
      options = {
        adIdAndroid: this.info.admob_android_publisher_id,
        adIdIos: this.info.admob_ios_publisher_id,
        isTesting: false,
      };
    }

    const actions: any = {
      show: async () => await AdMobAdvanced.showInterstitial(),
      load: async () => await AdMobAdvanced.loadInterstitial(options),
    };

    if (!actions[action]) {
      return null;
    }

    return await actions[action]();
  }

  private async bannerActions(action: BannerActions): Promise<void> {
    if (!this.canshowBannerAdmob || !this.platform.is('capacitor')) {
      return null;
    }

    // quando houver error ao mostrar o banner
    // e for chamado o resume não deve chamar o método do plugin
    if (action === 'resume' && this.failedToShowBanner) {
      return null;
    }

    let banner: BannerAdOptions;
    if (action === 'show') {
      banner = new BannerBuilder()
        .adIdAndroidBanner(this.info.admob_android_banner_id)
        .adIdIosBanner(this.info.admob_ios_banner_id)
        .isTesting(false)
        .build();
    }

    return await this.dispatchAction(action, banner);
  }

  private async dispatchAction(
    action: BannerActions,
    banner?: BannerAdOptions,
  ): Promise<void> {
    const actions: any = {
      hide: async () => {
        this.store.dispatch(new ShowBanner(false));
        return await AdMobAdvanced.hideBanner();
      },
      resume: async () => {
        this.store.dispatch(new ShowBanner(true));
        return await AdMobAdvanced.resumeBanner();
      },
      show: async () => {
        this.store.dispatch(new ShowBanner(true));
        return await AdMobAdvanced.showBanner(banner);
      },
      remove: async () => await AdMobAdvanced.removeBanner(),
    };

    if (!actions[action]) {
      return null;
    }

    return await actions[action]();
  }

  private routeEventsListener(): void {
    const home: (string | number)[] = this.navigationService.getHomeRoute();
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(({ url }: NavigationEnd) => {
        this.triggerBanner(url);
        if (home.join('/') === url || url.includes('/home')) {
          this.index = ++this.index;
          this.triggerInterstitial();
        }
      });
  }

  /**
   * @description
   * chama os eventos de mostrar
   * banner ou esconder banner
   * de acordo com rota
   * @returns void
   */
  private triggerBanner(url: string): void {
    const canHideBanner: boolean = this.routesForHide.some((route: string) =>
      url.includes(route),
    );

    if (canHideBanner) {
      this.hideBanner();
    } else {
      // necessário dar resume no banner
      // para o banner ser mostrado e mostrar a div
      // de separação do anúncio
      this.resumeBanner();
    }
  }

  /**
   * @description
   * caso seja a quarta vez na rota home,
   * mostra o interstitial
   * @returns Promise
   */
  private async triggerInterstitial(): Promise<void> {
    if (this.index === 4) {
      this.index = 0;
      this.showInterstitial();

      if (!window['preview']) {
        this.store.dispatch(new ShowModalFabApp(DivulgationModalComponent));
      }
    }
  }
}
