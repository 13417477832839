import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { CountryDDI } from '@core/models';
import { LoadCountriesDDI } from '@core/state/core/core.actions';
import { CoreState } from '@core/state/core/core.state';
import { Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'fabapp-country-ddi-modal',
  templateUrl: 'country-ddi-modal.component.html',
  styleUrls: ['country-ddi-modal.component.scss'],
})
export class CountryDDIModalComponent implements OnInit {
  @Select(CoreState.getCountriesList) countries$: Observable<CountryDDI[]>;
  filteredCountryList: CountryDDI[];
  searchString = '';

  inputTitle: Observable<string>;
  constructor(
    private modalController: ModalController,
    private store: Store,
    translocoService: TranslocoService,
  ) {
    this.inputTitle = translocoService.selectTranslate(
      'searchCountry',
      {},
      `country/${translocoService.getActiveLang()}`,
    );
  }

  ngOnInit(): void {
    this.loadCountries();
  }

  dismissModal(): void {
    this.modalController.dismiss();
  }

  loadCountries(): void {
    const countries: CountryDDI[] = this.store.selectSnapshot(
      CoreState.getCountriesList,
    );

    if (countries.length === 0) {
      this.store.dispatch(new LoadCountriesDDI());
    }
  }

  filterCountries(event: any): void {
    const searchString: string = event.detail.value;
    this.searchString = searchString.toLocaleLowerCase();
  }

  selectCountry(country: any): void {
    this.modalController.dismiss(country);
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }
}
