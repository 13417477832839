<ng-container *ngIf="bannerData$ | async as banner">
  <!-- gap para espaçamento dos conteudos -->
  <div class="gap" *ngIf="showBanner"></div>
  <ng-container *ngIf="banner?.showCustomBanner && banner?.image && showBanner">
    <!-- video  -->
    <video
      *ngIf="banner?.isVideo"
      (click)="openLink(banner?.link)"
      [src]="banner.image"
      poster="/assets/banner/poster.svg"
      [muted]="true"
      playsinline
      loop
      autoplay
    ></video>
    <!-- imagem -->
    <!-- 
    aparece se não for video
    tiver imagem e showCustomBanner igual true
     
  -->
    <img
      *ngIf="!banner?.isVideo"
      (click)="openLink(banner?.link)"
      [src]="banner.image"
      [alt]="banner.imageTitle"
    />
  </ng-container>

  <!-- background para o admob -->
  <div
    class="admobbanner"
    *ngIf="showBanner && (!banner?.showCustomBanner || !banner.image)"
  ></div>
</ng-container>
