import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { EmptyStateComponent } from './empty-state.component';
import { EmptyStateDirective } from './empty-state.directive';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
  imports: [IonicModule],
  exports: [EmptyStateComponent, EmptyStateDirective, LoaderComponent],
  declarations: [EmptyStateComponent, EmptyStateDirective, LoaderComponent],
})
export class EmptyStateModule {}
