import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import {
  Router,
  NavigationEnd,
  RouteConfigLoadEnd,
  ActivatedRoute,
} from '@angular/router';
import { CoreState } from '@core/state/core/core.state';

@Directive({ selector: '[isHomePage]' })
export class IsHomePageDirective {
  private show: string;
  private sub: Subscription;

  @Input() set isHomePage(show: 'back' | 'menu' | 'none') {
    this.show = show;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store,
    public router: Router,
    private cdr: ChangeDetectorRef,
    public route: ActivatedRoute,
  ) {
    console.log('url', this.router.url);
    if (this.show !== 'none') {
      this.sub = combineLatest([
        this.store.select(CoreState.getHomeRoute),
        this.router.events,
      ]).subscribe((value: any) => {
        // TODO: deixar mais declarativo o que é value ?
        const homeRoute: string = value[0].join('');

        if (value[1] instanceof NavigationEnd) {
          console.log(value);
          const url: string = value[1].urlAfterRedirects.includes('/home')
            ? '/'
            : value[1].url;
          this.run(url === homeRoute);
          this.sub.unsubscribe();
        }

        if (value[1] instanceof RouteConfigLoadEnd) {
          this.run(false);
          this.sub.unsubscribe();
        }
      });
    }
  }

  run(isHome: boolean): void {
    if (!isHome && this.show === 'back') {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (this.show === 'none' || (this.show === 'menu' && isHome)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (this.show === 'back' && isHome) {
      this.viewContainer.clear();
    }
    this.cdr.detectChanges();
  }
}
