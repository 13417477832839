import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  NavigationEnd,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GroupMobletPage } from '../../moblets/group/group.page';
import { UserStatus } from '../enums';
import { AppDef, AppInfo, Page, User } from '../models';
import { AppDefState } from '@core/state/appdef';
import { AuthState } from '@core/state/auth';
import { ModalController } from '@ionic/angular';
import { LoginPage } from 'src/app/pages/auth/login/login.page';
import { SignupPage } from 'src/app/pages/auth/signup/signup.page';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivateChild {
  private appInfo: AppInfo;
  private previousUrl: string;
  private currentUrl: string;

  constructor(
    private store: Store,
    private router: Router,
    private modalController: ModalController,
  ) {
    this.currentUrl = this.router.url;

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    const partialAppDef: Partial<AppDef> = this.store.selectSnapshot(
      AppDefState.getFromAppDef(['info']),
    );
    this.appInfo = partialAppDef.info;

    if (
      !this.isMoblet(route) &&
      this.isRequiredLogin() &&
      !this.isAuthenticated()
    ) {
      this.goToLogin();
      return;
    }

    if (this.mobletRequiredAuth(route) && !this.isAuthenticated()) {
      this.goToLogin(state.url);
      return;
    }

    if (this.isRequiredLogin() && !this.isAuthenticated()) {
      this.goToLogin();
      return;
    }

    if (
      this.isUserWaiting() &&
      (this.isRequiredLogin() || this.mobletRequiredAuth(route))
    ) {
      return this.goToUserAwaitingApproval();
    }

    return true;
  }

  async goToLogin(intentUrl?: string): Promise<boolean> {
    if (intentUrl) {
      const loginModal: any = await this.openLoginModal(intentUrl);

      if (loginModal?.navigate) {
        return this.router.navigateByUrl(intentUrl);
      }

      if (loginModal?.openSignUp) {
        const signUpModal: any = await this.openSignUpModal(intentUrl);

        if (signUpModal?.navigate) {
          return this.router.navigateByUrl(intentUrl);
        }
      }

      if (!this.previousUrl) {
        this.router.navigate(['/']);
      }
    } else {
      return this.router.navigate(['/auth/login'], {
        queryParams: { intentUrl },
      });
    }
  }

  async openSignUpModal(intentUrl: string): Promise<any> {
    const modal: HTMLIonModalElement = await this.modalController.create({
      component: SignupPage,
      componentProps: {
        isModal: true,
        intentUrl,
      },
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    return data;
  }

  async openLoginModal(intentUrl: string): Promise<any> {
    const modal: HTMLIonModalElement = await this.modalController.create({
      component: LoginPage,
      componentProps: {
        isModal: true,
        intentUrl,
      },
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    return data;
  }

  goToUserAwaitingApproval(): boolean {
    this.router.navigate(['/auth/user-awaiting-approval']);
    return false;
  }

  isMoblet(route: ActivatedRouteSnapshot): boolean {
    return route.params.id ? true : false;
  }

  mobletRequiredAuth(route: ActivatedRouteSnapshot): boolean {
    if (!this.isMoblet(route)) {
      return false;
    }
    const page: Page = this.store.selectSnapshot(
      AppDefState.getPage(route.params.id),
    );
    return page?.access_control === 'true';
  }

  isRequiredLogin(): boolean {
    return this.appInfo.login_required;
  }

  isAuthenticated(): boolean {
    return this.store.selectSnapshot(AuthState.isAuthenticated);
  }

  isUserWaiting(): boolean {
    const user: User = this.store.selectSnapshot(AuthState.getUser);
    if (!user) {
      return false;
    }
    return user.status_id === UserStatus.Waiting;
  }

  isGroup(route: ActivatedRouteSnapshot): boolean {
    return route.component === GroupMobletPage;
  }

  isOptionalLogin(): boolean {
    return !this.appInfo.login_enable;
  }
}
