import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { User } from '@core/models';
import { NavigationService } from '@core/services/navigation.service';
import { AuthState, Logout, RefreshToken } from '@core/state/auth';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

export class RefreshTokenService {
  delay: number = 600;
  constructor(
    private store: Store,
    private navigationService: NavigationService,
  ) {}

  async handleResponseError(
    error: HttpErrorResponse,
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Promise<Observable<HttpEvent<any>>> {
    const user: User = this.store.selectSnapshot(AuthState.getUser);
    if (error.status !== 401 || !user || req.url.includes('auth/refresh')) {
      return throwError(error);
    }

    try {
      // switchMap => realiza a requisição anterior novamente
      await this.store
        .dispatch(new RefreshToken())
        .pipe(
          delay(this.delay),
          switchMap(() => next.handle(req)),
        )
        .toPromise();
    } catch (error) {
      await this.store.dispatch(new Logout());
      this.navigationService.returnToHomePage();
      return throwError(error);
    }
  }
}
