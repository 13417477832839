import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DaiaService {
  constructor(private httpClient: HttpClient, private store: Store) {}

  get(path: string, params: any): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.daia.url}/${path}`;
    return this.httpClient.get(url, { params });
  }

  post(path: string, params: any = {}): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.daia.url}/${path}`;
    return this.httpClient.post(url, params);
  }
}
