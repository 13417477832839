import { Injectable } from '@angular/core';
import { MobletService } from '@core/services/moblet.service';
import {
  Action,
  createSelector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { LoadGroup, LoadMoblet, LoadMobletItem } from './moblets.actions';

@State({
  name: 'moblets',
})
@Injectable()
export class MobletsState {
  constructor(private mobletService: MobletService, private store: Store) {}

  static getMoblet(id: number): (state: any) => any {
    return createSelector([MobletsState], (state: any) => {
      return state[id];
    });
  }

  static getMobletItem(
    mobletId: number,
    itemId: number | string,
  ): (state: any) => any {
    return createSelector([MobletsState], (state: any) => {
      console.log(state[mobletId]);
      if (state[mobletId]) {
        return (
          state[mobletId].items
            .map((item: any) => (item.item ? item.item : item))
            // tslint:disable-next-line: triple-equals
            .find((item: any) => item.id == itemId)
        );
      }

      return null;
    });
  }

  @Action(LoadMobletItem)
  loadMobletItem(ctx: StateContext<any>, { id, item }: LoadMobletItem): any {
    const itemLoaded: any = this.store.selectSnapshot(
      MobletsState.getMobletItem(id, item),
    );
    if (itemLoaded) {
      console.log('ITEM NA MEMORIA');
      return itemLoaded;
    }
    console.log('CONSULTANDO');

    return this.mobletService.loadMoblet(id).pipe(
      // TODO tipar com moblet
      tap((moblet: any) => {
        const state: any = ctx.getState();
        ctx.setState({
          ...state,
          [id]: moblet,
        });
      }),
    );
  }

  @Action(LoadMoblet)
  async loadMoblet(
    ctx: StateContext<any>,
    { id, page, version, extraParams }: LoadMoblet,
  ): Promise<any> {
    const state: any = ctx.getState();
    let moblet: any = state[id];

    const mobletFromApi: any = await this.mobletService
      .loadMoblet(id, page, version, extraParams)
      .toPromise();

    if (moblet) {
      let items = [...moblet.items, ...mobletFromApi.items];
      if (page === 1) {
        items = moblet.items.filter(localItem =>
          mobletFromApi.items.find(apiItem => localItem.id === apiItem.id),
        );
      }

      moblet = {
        ...moblet,
        ...mobletFromApi,
        items,
      };
    } else {
      moblet = mobletFromApi;
    }

    // console.log(moblet);

    ctx.setState({
      ...state,
      [id]: moblet,
    });
  }

  @Action(LoadGroup)
  loadGroup(ctx: StateContext<any>, { id }: LoadMoblet): void {
    this.mobletService.loadGroup(id).then(([pages, groupLayout]: any) => {
      console.log(pages, groupLayout);
      const state: any = ctx.getState();
      ctx.setState({
        ...state,
        [id]: { ...pages, layout: groupLayout.layout },
      });
    });
  }
}
