import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CountryDDI } from '@core/models';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HeraService {
  appId: string;
  constructor(private httpClient: HttpClient, private store: Store) {
    if (window['appId']) {
      this.appId = window['appId'].toString();
    }
  }

  private headers(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  getCountriesInfo(): Observable<CountryDDI[]> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/settings/countries?fields[]=name&fields[]=flag&fields[]=callingCodes&fields[]=alpha2Code`;

    return this.httpClient.get<CountryDDI[]>(url, { headers: this.headers() });
  }

  regularSignUp(body: any): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/users`;

    return this.httpClient.post<any>(url, body, { headers: this.headers() });
  }

  updateUser(body: any): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/users/me`;

    return this.httpClient.put<any>(url, body, { headers: this.headers() });
  }

  uploadAvatar(avatar: any): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/users/me/avatar`;

    const body: FormData = new FormData();
    body.append('avatar', avatar);

    return this.httpClient.post<any>(url, body, {
      withCredentials: true,
    });
  }

  deleteAvatar(): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/users/me/avatar`;
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.httpClient.delete<any>(url, { headers });
  }

  deleteUser(): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/users/me`;

    return this.httpClient.delete<any>(url, { headers: this.headers() });
  }

  resetPasswordCodeRequest(username: string): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/auth/forgot`;
    return this.httpClient.post<any>(
      url,
      { email: username },
      { headers: this.headers() },
    );
  }

  resetPasswordConfirmationCode(email: string, token: string): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/auth/forgot/validate`;
    const body = {
      email,
      token,
    };

    return this.httpClient.post<any>(url, body, { headers: this.headers() });
  }

  resetPassword(
    email: string,
    token: string,
    password: string,
    passwordConfirmation: string,
  ): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/auth/reset`;
    const body = {
      email,
      token,
      password,
      password_confirmation: passwordConfirmation,
    };

    return this.httpClient.post<any>(url, body, { headers: this.headers() });
  }
}
