<ion-header translucent>
  <ion-toolbar mode="md">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="searchTitle | async as title">{{ title }}</ion-title>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <ion-list *ngIf="results && results.length > 0 && !loading">
    <ion-item *ngFor="let result of results"></ion-item>
  </ion-list>

  <div class="spinner-wrapper" *ngIf="results && loading">
    <ion-spinner></ion-spinner>
  </div>
</ion-content>
<fabapp-banner-footer></fabapp-banner-footer>
