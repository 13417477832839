import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Page } from '@core/models';
import { AppDefState } from '@core/state/appdef';
import { CoreState } from '@core/state/core/core.state';
import { NavController, Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { getRouteNameBySuperClass } from '@utils';
import { InAppBrowserService } from './in-app-browser.service';
import { MobletService } from './moblet.service';
const { Browser, Toast } = Plugins;

interface NavigationParameters {
  instanceId: number;
  itemId?: number | string;
}

type RouteArray = (number | string)[];

@Injectable({ providedIn: 'root' })
export class NavigationService {
  _url: string;
  constructor(
    private navCtrl: NavController,
    private store: Store,
    private platform: Platform,
    protected mobletService: MobletService,
    private inAppBrowser: InAppBrowserService,
  ) {}

  navigate({ instanceId, itemId }: NavigationParameters): void {
    try {
      const routeArray: RouteArray = this.geneterateRouteArray({
        instanceId,
        itemId,
      });

      if (this.inAppBrowser.mustOpenBrowserWhenLogged(instanceId)) {
        return this.inAppBrowser.redirecToLogin();
      }

      if (this.inAppBrowser.mustOpenBrowser) {
        this.inAppBrowser.getMobletInfo(instanceId);
        return;
      }

      this.navCtrl.navigateForward(routeArray);
    } catch (err) {
      console.error('navigation error', err);
    }
  }

  back({ instanceId, itemId }: NavigationParameters): void {
    try {
      const routeArray: RouteArray = this.geneterateRouteArray({
        instanceId,
        itemId,
      });

      this.navCtrl.navigateBack(routeArray);
    } catch (err) {
      console.error('navigation back error', err);
    }
  }

  geneterateRouteArray({
    instanceId,
    itemId,
  }: NavigationParameters): (number | string)[] {
    const superClass: string = this.getPageSuperClass(instanceId);
    this.inAppBrowser.superClass = superClass;

    if (!superClass) {
      throw new Error('n tem superclass');
    }

    const routeName: string = getRouteNameBySuperClass(superClass);

    if (!routeName) {
      throw new Error('n tem esse moblet ainda');
    }

    const routeArray: (string | number)[] = [routeName, instanceId];

    if (itemId) {
      routeArray.push(itemId);
    }

    return routeArray;
  }

  returnToHomePage(): void {
    const homeRoute: any[] = this.store.selectSnapshot(CoreState.getHomeRoute);
    this.navCtrl.navigateRoot(homeRoute);
  }

  getHomeRoute(): (string | number)[] {
    return this.store.selectSnapshot(CoreState.getHomeRoute);
  }

  getPageSuperClass(instanceId: number): string {
    const page: Page = this.store.selectSnapshot(
      AppDefState.getPage(instanceId),
    );

    return page ? page.moblet_name : null;
  }

  handleLinkClick(event: any): void | Window {
    if (event.target.dataset.moblet) {
      const [instanceId, itemId] = event.target.dataset.moblet.split('/');
      event.preventDefault();

      return this.navigate({ instanceId, itemId });
    }

    if (event.target && event.target.href) {
      const target: string = this.platform.is('cordova') ? '_system' : '_blank';
      event.preventDefault();

      if (event.target.href.indexOf(window.location.host) === -1) {
        return window.open(event.target.href, target, 'location=yes');
      }
    }
  }
}
