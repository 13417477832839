import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  FabappAdsState,
  SetAdsAsClicked,
  SetAdsAsViewed,
} from '@core/state/ads';
import { ModalController } from '@ionic/angular';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BannerAndModalOfDivulgation } from '../banner-footer/interfaces/banner.model';

@Component({
  templateUrl: './divulgation-modal.component.html',
  styleUrls: ['./divulgation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'divulgation-modal',
    },
  ],
})
export class DivulgationModalComponent implements OnInit {
  public counter: number = 5;
  public modalData$: Observable<BannerAndModalOfDivulgation>;
  constructor(
    private modalCtrl: ModalController,
    private cdr: ChangeDetectorRef,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new SetAdsAsViewed('modal'));
    this.counting();
    this.loadModal();
  }

  loadModal(): void {
    this.modalData$ = this.store.select(FabappAdsState.modal);
  }

  private counting(): void {
    const interval: NodeJS.Timeout = setInterval(() => {
      this.counter = --this.counter;
      this.cdr.markForCheck();
      // tslint:disable-next-line: no-unused-expression
      this.counter === 0 && clearInterval(interval);
    }, 1000);
  }

  openLink(link: string): void {
    this.store.dispatch(new SetAdsAsClicked('modal'));
    if (link && typeof link !== 'number') {
      window.open(link, '_blank');
    }
  }

  public async dismiss(): Promise<void> {
    await this.modalCtrl.dismiss();
  }
}
