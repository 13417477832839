import { Injectable } from '@angular/core';
import { User } from '@core/models/user.model';
import { HeraService } from '@core/services/hera.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { convertToBoolean } from '@utils';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../../pages/auth/services/auth.service';
import {
  DeleteAvatar,
  DeleteUser,
  Login,
  Logout,
  RefreshToken,
  RegularSignUp,
  UpdateUser,
  UploadAvatar,
} from './auth.actions';

export interface AuthStateModel {
  loading: boolean;
  user: User;
  access_token: string;
  refresh_token: string;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    loading: false,
    user: null,
    access_token: null,
    refresh_token: null,
  },
})
@Injectable()
export class AuthState {
  constructor(
    private heraService: HeraService,
    private authService: AuthService,
  ) {}

  @Selector()
  static accessToken(state: AuthStateModel): string {
    return state.access_token;
  }

  @Selector()
  static refreshToken(state: AuthStateModel): string {
    return state.refresh_token;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.access_token;
  }

  @Selector()
  static getUser(state: AuthStateModel): User {
    return state.user;
  }

  @Selector()
  static acceptsPush(state: AuthStateModel): Partial<User> {
    return { accepts_push: convertToBoolean(state.user.accepts_push) };
  }

  @Action(RefreshToken)
  async refreshToken(
    ctx: StateContext<AuthStateModel>,
    {}: any,
  ): Promise<any> | null {
    const state: AuthStateModel = ctx.getState();
    if (!state.user || !state.refresh_token) {
      return null;
    }
    await this.authService
      .refreshToken({ refresh_token: state.refresh_token })
      .pipe(
        tap((payload: AuthStateModel) => {
          ctx.patchState(payload);
        }),
      )
      .toPromise();
  }

  @Action(RegularSignUp)
  async regularSignUp(
    ctx: StateContext<AuthStateModel>,
    { user }: any,
  ): Promise<any> {
    const state: AuthStateModel = ctx.getState();
    const response: any = await this.heraService
      .regularSignUp(user)
      .toPromise();

    await ctx
      .dispatch(new Login({ email: response.email, password: user.password }))
      .toPromise();
  }

  @Action(UpdateUser)
  async updateUser(
    ctx: StateContext<AuthStateModel>,
    { user }: any,
  ): Promise<any> {
    const state: AuthStateModel = ctx.getState();
    const response: any = await this.heraService.updateUser(user).toPromise();

    ctx.patchState({ user: response });
  }

  @Action(UploadAvatar)
  async uploadAvatar(
    ctx: StateContext<AuthStateModel>,
    { avatar }: any,
  ): Promise<any> {
    const response: any = await this.heraService
      .uploadAvatar(avatar)
      .toPromise();
    const user: User = {
      ...ctx.getState().user,
      ...{ picture: response.avatarUrl },
    };
    ctx.patchState({ user });
  }

  @Action(DeleteAvatar)
  async deleteAvatar(ctx: StateContext<AuthStateModel>): Promise<void> {
    try {
      await this.heraService.deleteAvatar().toPromise();
      const user: User = {
        ...ctx.getState().user,
        ...{ picture: '' },
      };

      ctx.patchState({ user });
    } catch (error) {
      console.log('Error for delete avatar');
    }
  }

  @Action(DeleteUser)
  async deleteUser(ctx: StateContext<AuthStateModel>): Promise<any> {
    await this.heraService.deleteUser().toPromise();
  }

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login) {
    console.log(action.payload);
    return this.authService.login(action.payload as any).pipe(
      tap((payload: AuthStateModel) => {
        ctx.patchState(payload);
      }),
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    return this.authService.logout().pipe(
      tap(() => {
        ctx.setState({
          loading: false,
          access_token: null,
          refresh_token: null,
          user: null,
        });
      }),
    );
  }
}
