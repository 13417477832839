<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon
          [class.ion-hide]="counter !== 0"
          slot="icon-only"
          name="close-outline"
        ></ion-icon>

        <span class="counter" [class.ion-hide]="counter === 0">{{
          counter
        }}</span>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="vertical-center" *transloco="let t">
    <div
      class="inner-wrapper ion-text-center"
      *ngIf="modalData$ | async as data"
    >
      <div class="header">
        <div class="headline" [innerHTML]="t.headline"></div>
        <div class="subheadline" [innerHTML]="t.subheadline"></div>
      </div>
      <div class="middle">
        <ion-img
          class="img"
          *ngIf="data?.image && !data?.isVideo"
          [src]="data.image"
          [alt]="data.imageTitle"
        ></ion-img>
        <video
          [muted]="true"
          playsinline
          loop
          autoplay
          class="video"
          *ngIf="data?.image && data?.isVideo"
          [src]="data.image"
        ></video>
      </div>

      <ion-button (click)="openLink(data.link)" class="btn">{{
        t.iWantAApp
      }}</ion-button>
    </div>
  </div>
</ion-content>
