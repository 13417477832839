<ion-header class="ion-no-border">
  <ion-toolbar *ngIf="partialAppDef$ | async as partialAppDef">
    <ion-buttons *ngIf="!partialAppDef.info?.login_required" slot="start">
      <ion-back-button *ngIf="!isModal" defaultHref="home"></ion-back-button>
      <ion-button
        *ngIf="isModal"
        fill="clear"
        (click)="modalController.dismiss()"
      >
        <ion-icon slot="icon-only" name="close" color="dark"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ng-container *transloco="let t">
  <ion-content class="ion-padding-horizontal">
    <div
      class="ion-text-center"
      *ngIf="partialAppDef$ | async as partialAppDef"
    >
      <ion-thumbnail slot="center" *ngIf="partialAppDef.info?.login_image">
        <ion-img
          class="login_image"
          [src]="partialAppDef.info.login_image"
          [alt]="partialAppDef.info?.name"
        >
        </ion-img>
      </ion-thumbnail>
      <h1 class="headline">
        {{ partialAppDef.info?.login_heading || t.title }}
      </h1>
      <p class="info">
        {{ partialAppDef.info?.login_description || t.welcome }}
      </p>

      <div class="wrapper-email">
        <form [formGroup]="form">
          <ion-list class="ion-no-padding" lines="none">
            <ion-item>
              <ion-label position="floating"
                >{{t.fields.username.label}}</ion-label
              >
              <fabapp-phone-email-input
                type="email"
                formControlName="email"
                outline
                [label]="t.fields.username.label"
                [isEmail]="isEmail"
                [brmasker]="mask"
                (input)="onInputUsername($event.target.value)"
                (setMask)="setMask($event)"
                (getCallingCode)="getCallingCode($event)"
              >
              </fabapp-phone-email-input>
            </ion-item>
            <control-error
              [label]="t.fields.username.label"
              [control]="email"
            ></control-error>

            <ion-item
              [@slideDown]="email.valid"
              [class.no-margin]="!email.valid"
            >
              <ion-label position="floating"
                >{{t.fields.password.label}}</ion-label
              >
              <ion-input
                #passwordField
                outline
                formControlName="password"
                [type]="passwordType"
              ></ion-input>
              <ion-icon
                slot="end"
                class="ion-align-self-center"
                [name]="passwordType == 'password' ? 'eye' : 'eye-off'"
                (click)="togglePasswordMode()"
              >
              </ion-icon>
            </ion-item>
            <control-error
              [class.ion-hide]="email.invalid"
              [label]="t.fields.password.label"
              [control]="password"
            ></control-error>
          </ion-list>
        </form>

        <div class="ion-text-start ion-padding-vertical">
          <a
            class="nav-forget-password"
            routerLink="/reset-password/code-request"
            routerDirection="forward"
          >
            {{t.buttons.forgotPassword}}
          </a>
        </div>
        <div class="separator">
          {{t.orSocial}}
        </div>
        <ion-grid class="ion-margin-bottom">
          <ion-row>
            <ion-col col-4>
              <ion-button class="social-buttons" expand="block" fill="outline">
                <ion-icon
                  slot="icon-only"
                  src="assets/icons/logo-apple.svg"
                  color="dark"
                ></ion-icon>
              </ion-button>
            </ion-col>
            <ion-col col-4>
              <ion-button class="social-buttons" expand="block" fill="outline">
                <ion-icon
                  slot="icon-only"
                  src="assets/icons/logo-facebook.svg"
                ></ion-icon>
              </ion-button>
            </ion-col>
            <ion-col col-4>
              <ion-button class="social-buttons" expand="block" fill="outline">
                <ion-icon
                  slot="icon-only"
                  src="assets/icons/logo-google.svg"
                ></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
  </ion-content>

  <ion-footer class="ion-no-border">
    <ion-toolbar>
      <ion-grid>
        <ion-row class="ion-align-items">
          <ion-col col-6>
            <ion-button
              [disabled]="isSubmitting"
              expand="block"
              class="actionbuttons"
              fill="clear"
              (click)="goToNewAccount()"
            >
              {{t.buttons.newAccount}}</ion-button
            >
          </ion-col>
          <ion-col col-6>
            <ion-button
              [disabled]="isSubmitting"
              expand="block"
              class="actionbuttons"
              color="primary"
              [validate]="form"
              (click)="login()"
            >
              {{t.buttons.login}}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-footer>
</ng-container>
